import api from "./api";
import { getArgs } from "../utils";

const route = "/cart";

const CartsService = {
  getCarts: (filters) => api.get(`${route}/admin?${getArgs(filters)}`),
  deleteCart: (cart_id) => api.delete(`${route}/admin/${cart_id}`),
};

export default CartsService;