import { getArgs } from "../utils";
import api from "./api";

const route = "/milestone_benefits";

const MilestoneBenefitsService = {
  getMilestoneBenefits: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleMilestoneBenefit: (milestone_benefit_id) =>
    api.get(`${route}/${milestone_benefit_id}`),
  postMilestoneBenefit: (data) =>
    api.post(route, { ...data }),
  putMilestoneBenefit: (data) =>
    api.put(route, { ...data }),
  deleteMilestoneBenefit: (milestone_benefit_id) =>
    api.delete(`${route}/${milestone_benefit_id}`),
};

export default MilestoneBenefitsService;
