import React, { useContext } from "react";
import moment from "moment";
import MapaLugares from "../schedule/MapaLugares";
import { formatMonto, getValue } from "../../utils";
import SingleClassActions from "./SingleClassActions";
// import { getLocalMomentObject } from "../../utils/dates";
import { AuthContext } from "../../context/AuthContext";
import RatingComments from "../ratings/RatingComments";
import { ModalContext } from "../../context/ModalContext";

const SingleClassData = ({ notifying, setNotifying, single_class, handleCallback }) => {
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const getSpots = () => {
    if (single_class.class_type) {
      return single_class.class_type?.spot_map
        .split(",")
        .map((number) => parseInt(number));
    }
  };

  const renderClassTypeName = () => {
    if (single_class.class_type && single_class.class_type !== null) {
      return single_class.class_type.name;
    }
  };

  const renderLocationName = () => {
    if (single_class.location && single_class.location !== null) {
      return single_class.location.name;
    }
  };

  const renderButtons = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <SingleClassActions
          notifying={notifying}
          setNotifying={setNotifying}
          single_class={single_class}
          handleCallback={handleCallback}
        />
      );
    }
  };

  const renderSpotMap = () => {
    if (
      single_class.class_type !== null &&
      single_class.class_type?.spot_map !== null
    ) {
      return (
        <MapaLugares
          rows={getSpots()}
          taken_spots={single_class?.asistentes?.map(({ spot }) => spot)}
        />
      );
    }
  };

  const handleComments = () => {
    modalComponent(
      "Comentarios",
      <RatingComments
        ratings={single_class.asistentes}
        handleCancel={clearModal}
      />
    );
  };

  const renderRating = () => {
    if (single_class.class_stars_average !== null) {
      return (
        <div className="d-flex mb-3">
          <span className="me-2">
            <div>
              {formatMonto(single_class.class_stars_average)}{" "}
              <i className="fa fa-star ms-1 text-warning"></i>
            </div>
            <p style={{ fontSize: "12px" }} className="mb-0">
              Clase
            </p>
          </span>
          <span className="me-2">
            <div>
              {formatMonto(single_class.coach_stars_average)}{" "}
              <i className="fa fa-star ms-1 text-warning"></i>
            </div>
            <p style={{ fontSize: "12px" }} className="mb-0">
              Coach
            </p>
          </span>
          <button
            key="comments"
            onClick={handleComments}
            className="btn text-dark d-inline-block"
          >
            {formatMonto(single_class.comments)}
            <i className="fa fa-comment ms-1"></i>
          </button>
        </div>
      );
    }
    return <p className="small text-muted">Sin calificar</p>;
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <h4>Datos de Clase</h4>
        <p>
          <i className="fa fa-calendar me-2 text-primary" />
          {moment(getValue(single_class, "class_date", "date")).format("ddd DD MMM, YY")}{" "}
          <i className="fas fa-clock me-1 ms-1 text-primary" />
          {getValue(single_class, "class_date", "time")}
        </p>
        <p>{renderClassTypeName()}</p>
        <p><i className="fa fa-map-pin me-2 text-primary" />{renderLocationName()}</p>
        {renderRating()}
        {renderButtons()}
      </div>
      <div className="col col-md-6">{renderSpotMap()}</div>
    </div>
  );
};

export default SingleClassData;
