import React from "react";
import HTMLReactParser from "html-react-parser";

const Content = ({ title, content, list, buttonLink, buttonText }) => {
  const renderText = () => {
    if (String(content).includes("<")) {
      return HTMLReactParser(content);
    }
    return content;
  };

  return (
    <div className="card no-scale shadow-sm">
      <h3>{title}</h3>
      <p>{renderText()}</p>
      {Array.isArray(list) && (
        <ul>
          {list.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      )}
      {buttonText && (
        <a href={buttonLink} className="btn btn-primary">
          {buttonText}
        </a>
      )}
    </div>
  );
};

const VideoText = ({
  video_source,
  title,
  content,
  videoSide,
  list,
  buttonLink,
  buttonText,
}) => {
  const renderVideo = () => {
    if (video_source && typeof video_source === "string") {
      if (video_source.includes("youtube")) {
        const source =
          "https://www.youtube.com/embed/" + video_source.split("v=")[1];
        return (
          <iframe
            src={source}
            title={title}
            height="350px"
            className="mw-100 w-100 shadow"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        );
      }
      if (video_source.includes("drive")) {
        return (
          <iframe
            height="400px"
            title={title}
            src={video_source}
            className="mw-100 w-100"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        );
      }
      return (
        <video className="mw-100 w-100" autoPlay muted controls>
          <source src={video_source} type="video/mp4" />
        </video>
      );
    }
  };
  const renderContent = (side) => {
    if (videoSide === side) {
      return renderVideo();
    }
    return (
      <Content
        title={title}
        content={content}
        list={list}
        buttonLink={buttonLink}
        buttonText={buttonText}
      />
    );
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">{renderContent("left")}</div>
        <div className="col-12 col-md-6">{renderContent("right")}</div>
      </div>
    </div>
  );
};

export default VideoText;
