import React from "react";
import HTMLReactParser from "html-react-parser";

const Content = ({ title, content, list, buttonLink, buttonText }) => {
  const renderText = () => {
    if (String(content).includes("<")) {
      return HTMLReactParser(content);
    }
    return content;
  };

  return (
    <div className="card no-scale shadow-sm">
      <h3>{title}</h3>
      <p>{renderText()}</p>
      {Array.isArray(list) && (
        <ul>
          {list.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      )}
      {buttonText && (
        <a href={buttonLink} className="btn btn-primary">
          {buttonText}
        </a>
      )}
    </div>
  );
};

const ImageText = ({
  src,
  title,
  content,
  imageSide,
  list,
  buttonLink,
  buttonText,
}) => {
  const renderContent = (side) => {
    if (imageSide === side) {
      return <img src={src} alt={title} className="mw-100 w-100" />;
    }
    return (
      <Content
        title={title}
        content={content}
        list={list}
        buttonLink={buttonLink}
        buttonText={buttonText}
      />
    );
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">{renderContent("left")}</div>
        <div className="col-12 col-md-6">{renderContent("right")}</div>
      </div>
    </div>
  );
};

export default ImageText;
