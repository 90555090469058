import React, { useContext } from "react";
import { ReservationsContext } from "../../context/ReservationsContext";

const ReservationTableFilters = () => {
  const {
    currentReservationsFilter,
    reservationFilters,
    setCurrentFilter
  } = useContext(ReservationsContext);

  const renderFilters = () => {
    if(Array.isArray(reservationFilters)) {
      return reservationFilters.map(filter => {
        const isSelected = filter.value === currentReservationsFilter;

        if(isSelected) {
          return (
            <button 
              key={filter.value}
              className="btn btn-sm btn-focus-disabled btn-primary"
              onClick={() => setCurrentFilter(filter.value)}
            >
              {filter.name}
            </button>
          )
        } else {
          return (
            <button 
              key={filter.value}
              className="btn btn-sm btn-focus-disabled btn-outline-primary"
              onClick={() => setCurrentFilter(filter.value)}
            >
              {filter.name}
            </button>
          )
        }
      });
    }
  }

  return (
    <div className="btn-group d-block mb-3 w-100">
      {renderFilters()}
    </div>
  );
};

export default ReservationTableFilters;
