import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import HeaderRow from "../../components/global/HeaderRow";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import { PackagesContext } from "../../context/PackageContext";
import AsistenteRow from "../../components/customers/AsistenteRow";
import { SingleClassContext } from "../../context/SingleClassContext";
import AgregarAsistente from "../../components/clases/AgregarAsistente";
import SingleClassData from "../../components/single_classes/SingleClassData";

const AdminAsistentesEspeciales = ({ class_package_id }) => {
  const { paquete, getAsistentesEspecial } = useContext(PackagesContext);
  const { modalComponent } = useContext(ModalContext);
  const { clearClase } = useContext(SingleClassContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getAsistentesEspecial(class_package_id);
    return clearClase;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_package_id]);

  const fetchAsistentes = () => {
    getAsistentesEspecial(class_package_id);
  };

  const addAsistente = () => {
    modalComponent(
      "Agregar Asistente",
      <AgregarAsistente
        single_class_id={paquete.single_class.single_class_id}
      />
    );
  };

  const renderAsistentes = () => {
    if (paquete && paquete !== null) {
      const asistentes = paquete.single_class.class_reservations;
      if (Array.isArray(asistentes)) {
        if (asistentes.length === 0) {
          return <p className="p-2">Aún no hay asistentes registrados</p>;
        }
        return asistentes.map((asistente) => (
          <AsistenteRow
            key={asistente.class_reservation_id}
            asistente={asistente}
            is_special_event
            user={user}
          />
        ));
      }
    }
  };

  const renderClase = () => {
    if (paquete && paquete !== null) {
      return <SingleClassData single_class={paquete.single_class} handleCallback={fetchAsistentes} />;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="contiainer-fluid">
      <PanelTitle title="Evento Especial" onClick={addAsistente} />
      <div className="card p-3 shadow-sm border no-scale">{renderClase()}</div>
      <HeaderRow
        headers={[
          "Nombre",
          "Instagram",
          "Correo",
          "Teléfono",
          "Lugar",
          "Acciones",
        ]}
      />
      {renderAsistentes()}
    </div>
  );
};

export default AdminAsistentesEspeciales;
