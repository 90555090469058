import React, { useEffect } from "react";
import { Link } from "@reach/router";
import { LOGO_SRC } from "../../constants";
import useUserRoles from "../../hooks/auth/useUserRoles";
import useBranch from "../../hooks/useBranch";

const PanelNavbar = ({ user, signOut }) => {
  const { isSuperAdmin, isAdmin, isManager } = useUserRoles();
  const { branches, branch, selectBranch } = useBranch();

  useEffect(() => {}, []);
  return (
    <nav className="bg-dark hide-mobile side-menu px-0 w-100">
      <div className="container-fluid px-0 " style={{ width: "160px" }}>
        <div className="px-3">
          <Link to="/" className="navbar-brand" href="#landing">
            <img src={LOGO_SRC} alt="logo" className="thumbnail" />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="text-white">
          <ul className="side-menu-list">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#clasesDropdown"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="clasesDropdown"
              >
                Clases
              </a>
              <ul id="clasesDropdown" className="collapse collapse-menu">
                <li className="nav-item text-item">
                  <Link to="/myadmin/clases" className="nav-link">
                    Horarios
                  </Link>
                </li>
                <li className="nav-item text-item">
                  <Link to="/myadmin/asistentes" className="nav-link">
                    Asistentes
                  </Link>
                </li>
              </ul>
            </li>
            {isManager() && (
              <>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#clientesDropdown"
                    role="button"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="clientesDropdown"
                  >
                    Clientes
                  </a>
                  <ul id="clientesDropdown" className="collapse collapse-menu">
                    <li className="nav-item text-item">
                      <Link to="/myadmin/customers" className="nav-link">
                        Clientes
                      </Link>
                    </li>
                    <li className="nav-item text-item">
                      <Link to="/myadmin/orders" className="nav-link">
                        Pagos
                      </Link>
                    </li>
                    <li className="nav-item text-item">
                      <Link to="/myadmin/online" className="nav-link">
                        Online
                      </Link>
                    </li>
                    <li className="nav-item text-item">
                      <Link to="/myadmin/presenciales" className="nav-link">
                        Presenciales
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#paquetesDropdown"
                    role="button"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="paquetesDropdown"
                  >
                    Paquetes
                  </a>
                  <ul className="collapse collapse-menu" id="paquetesDropdown">
                    <li className="nav-item text-item">
                      <Link to="/myadmin/paquetes" className="nav-link">
                        Regulares
                      </Link>
                    </li>
                    <li className="nav-item text-item">
                      <Link to="/myadmin/especiales" className="nav-link">
                        Especiales
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#atributosDropdown"
                    role="button"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="atributosDropdown"
                  >
                    Herramientas
                  </a>
                  <ul className="collapse collapse-menu" id="atributosDropdown">
                    {user.role === "super_admin" && (
                      <>
                        <li className="nav-item text-item">
                          <Link to="/myadmin/branches" className="nav-link">
                            Franquicias
                          </Link>
                        </li>
                      </>
                    )}
                    <li className="nav-item text-item">
                      <Link to="/myadmin/ubicaciones" className="nav-link">
                        Ubicaciones
                      </Link>
                    </li>
                    <li className="nav-item text-item">
                      <Link to="/myadmin/coaches" className="nav-link">
                        Coaches
                      </Link>
                    </li>
                    <li className="nav-item text-item">
                      <Link to="/myadmin/milestones" className="nav-link">
                        Niveles
                      </Link>
                    </li>
                    {isSuperAdmin() && (
                      <>
                        <li className="nav-item text-item">
                          <Link to="/myadmin/tipos" className="nav-link">
                            Tipos de Clase
                          </Link>
                        </li>
                        <li className="nav-item text-item">
                          <Link to="/myadmin/categorias" className="nav-link">
                            Categorías
                          </Link>
                        </li>
                        <li className="nav-item text-item">
                          <Link to="/myadmin/programs" className="nav-link">
                            Programas
                          </Link>
                        </li>
                        <li className="nav-item text-item">
                          <Link to="/myadmin/props" className="nav-link">
                            Props
                          </Link>
                        </li>
                        <li className="nav-item text-item">
                          <Link to="/myadmin/muscles" className="nav-link">
                            Músculos
                          </Link>
                        </li>
                        <li className="nav-item text-item">
                          <Link to="/myadmin/pages" className="nav-link">
                            Páginas
                          </Link>
                        </li>
                      </>
                    )}

                    <li className="nav-item text-item">
                      <Link to="/myadmin/testimonios" className="nav-link">
                        Testimonios
                      </Link>
                    </li>
                    <li className="nav-item text-item">
                      <Link to="/myadmin/descuentos" className="nav-link">
                        Descuentos
                      </Link>
                    </li>

                    <li className="nav-item text-item">
                      <Link to="/myadmin/users" className="nav-link">
                        Usuarios
                      </Link>
                    </li>
                    <li className="nav-item text-item">
                      <Link
                        to="/myadmin/frequent_questions"
                        className="nav-link"
                      >
                        Preguntas Frequentes
                      </Link>
                    </li>
                  </ul>
                </li>

                {isSuperAdmin() && (
                  <li className="nav-item text-item">
                    <Link to="/myadmin/videos" className="nav-link">
                      Videos
                    </Link>
                  </li>
                )}
              </>
            )}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#comunidad"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="comunidad"
              >
                Comunidad
              </a>
              <ul className="collapse collapse-menu" id="comunidad">
                <li className="nav-item text-item">
                  <Link to="/myadmin/comunidad" className="nav-link">
                    Chat
                  </Link>
                </li>
                <li className="nav-item text-item">
                  <Link
                    to="/myadmin/analytics/leaderboard"
                    className="nav-link"
                  >
                    Leaderboard Presenciales
                  </Link>
                </li>
                <li className="nav-item text-item">
                  <Link
                    to="/myadmin/analytics/leaderboard/online"
                    className="nav-link"
                  >
                    Leaderboard Online
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#calificaciones"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="calificaciones"
              >
                Ratings
              </a>
              <ul className="collapse collapse-menu" id="calificaciones">
                <li className="nav-item text-item">
                  <Link to="/myadmin/videos/ratings" className="nav-link">
                    Online
                  </Link>
                </li>
                <li className="nav-item text-item">
                  <Link
                    to="/myadmin/single_classes/ratings"
                    className="nav-link"
                  >
                    Presenciales
                  </Link>
                </li>
              </ul>
            </li>
            {user.role === "coach" && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#analiticasDropdown"
                  role="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="analiticasDropdown"
                >
                  Analíticas
                </a>
                <ul className="collapse collapse-menu" id="analiticasDropdown">
                  <li className="nav-item text-item">
                    <Link
                      to="/myadmin/analytics/coach/descuentos"
                      className="nav-link"
                    >
                      Mi Descuento
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link to="/myadmin/analytics/videos" className="nav-link">
                      Mis Videos
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {isAdmin() && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#analiticasDropdown"
                  role="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="analiticasDropdown"
                >
                  Analíticas
                </a>
                <ul className="collapse collapse-menu" id="analiticasDropdown">
                  <li className="nav-item text-item">
                    <Link
                      to="/myadmin/analytics/addresses"
                      className="nav-link"
                    >
                      Direcciones
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link
                      to="/myadmin/analytics/inscritos"
                      className="nav-link"
                    >
                      Inscritos
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link to="/myadmin/analytics/ingresos" className="nav-link">
                      Ingresos
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link
                      to="/myadmin/analytics/instructores"
                      className="nav-link"
                    >
                      Instructores
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link to="/myadmin/analytics/paquetes" className="nav-link">
                      Paquetes
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link
                      to="/myadmin/analytics/reservaciones"
                      className="nav-link"
                    >
                      Reservaciones
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link to="/myadmin/analytics/videos" className="nav-link">
                      Videos
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link
                      to="/myadmin/analytics/discounts"
                      className="nav-link"
                    >
                      Descuentos
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link to="/myadmin/analytics/free" className="nav-link">
                      Free Trials
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link to="/myadmin/analytics/trials" className="nav-link">
                      Clases Prueba
                    </Link>
                  </li>
                  <li className="nav-item text-item">
                    <Link
                      to="/myadmin/analytics/signup_reasons"
                      className="nav-link"
                    >
                      Motivo de Inscripción
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            <li className="nav-item dropdown">
              <button
                className="btn nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="navbarDropdown"
              >
                {String(user.customer.name).substr(0, 20)}
                {String(user.customer.name).length > 20 ? "..." : ""}
                <i className="ms-2 fa fa-user-circle"></i>
              </button>
              <ul
                className="dropdown-menu user-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <button
                    className="dropdown-item text-danger"
                    onClick={signOut}
                  >
                    Salir <i className="ms-2 fa fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </li>
            {isSuperAdmin() && (
              <>
                <li className="nav-item text-item">
                  <Link to="/myadmin/ajustes/" className="nav-link">
                    Ajustes
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <button
                    className="btn nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="navbarDropdown"
                  >
                    {branch.name}
                    <i className="ms-2 fas fa-building"></i>
                  </button>
                  <ul
                    className="dropdown-menu user-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    {branches
                      ?.filter(
                        (current) => current.branch_id !== branch.branch_id
                      )
                      .map((current) => (
                        <li key={current.branch_id}>
                          <button
                            className={`dropdown-item bold d-flex justify-content-between align-items-center`}
                            style={{ color: current.primary_color }}
                            onClick={() => {
                              selectBranch(current.branch_id);
                              window.location.reload();
                            }}
                          >
                            {current.name}{" "}
                            <i className=" far fa-building bold"></i>
                          </button>
                        </li>
                      ))}
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
