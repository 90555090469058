
import React, { createContext, useReducer, useContext } from 'react';
import NotesService from '../services/NotesService';
import NotesReducer from '../reducers/NotesReducer';

import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  NOTES_RECEIVED,
  SET_NOTE,
  CREATE_NOTE,
  SET_PROPERTY_NOTE,
} from "../types/notes";

const initialState = {
  notes: null,
  note: {
    customer_id: null,
    user_id: null,
    content: ''
  },
};

export const NotesContext = createContext(initialState);

export const NotesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotesReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getNotesByCustomer = (customer_id) => {
    NotesService.getNotesByCustomer(customer_id)
      .then((response) => {
        const { notes } = response.data;
        dispatch({ type: NOTES_RECEIVED, payload: notes });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleNote = (note_id) => {
    NotesService.getSingleNote(note_id)
      .then((response) => {
        const { note } = response.data;
        dispatch({ type: SET_NOTE, payload: note });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setNote = (note) => {
    dispatch({ type: SET_NOTE, payload: note });
  };

  const createNote = (user_id, customer_id) => {
    dispatch({ type: CREATE_NOTE, payload: { user_id, customer_id } });
  };

  const setPropertyNote = (key, value) => {
    dispatch({ type: SET_PROPERTY_NOTE, payload: { key, value } });
  };

  const saveNote = (note, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = NotesService.putNote;
      if(isNaN(parseInt(note.note_id))) {
        service = NotesService.postNote;
      }
      service(note).then(() => {
        success("Note saved.");
        dispatch({ type: HIDE_SPINNER });
        setPropertyNote('content', '');
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteNote = (note_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    NotesService.deleteNote(note_id).then(() => {
      success("Note deleted.");
      dispatch({ type: HIDE_SPINNER });
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <NotesContext.Provider
    value={{
      ...state,
      setNote,
      getNotesByCustomer,
      saveNote,
      deleteNote,
      createNote,
      getSingleNote,
      setPropertyNote,

    }}
  >
    {children}
  </NotesContext.Provider>
);
};
