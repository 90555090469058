import React, { useEffect, useRef } from "react";

function TextareaInput({
  label,
  description,
  valid,
  value,
  rows,
  handleChange,
  placeholder,
}) {
  useEffect(() => {
    setTimeout(() => {
      hanldeHeight();
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const inputRef = useRef();

  const hanldeHeight = () => {
    if (inputRef.current !== null) {
      if (value === "") {
        // inputRef.current.style.height = "0px";
      } else {
        inputRef.current.style.height = "auto";
        inputRef.current.style.height =
          inputRef.current.scrollHeight + 2 + "px";
      }
    }
  };

  const renderLabel = () => {
    if (label && label !== null && label !== "") {
      return (
        <label>
          <b>{label}</b>
        </label>
      );
    }
  };

  const renderDescription = () => {
    if (description && description && description !== "") {
      return (
        <label
          className={`${description === null ? "d-none" : ""} 
        small text-muted d-block mb-1`}
        >
          {description}
        </label>
      );
    }
  };

  return (
    <div>
      {renderLabel()}
      {renderDescription()}
      <textarea
        ref={inputRef}
        rows={rows ? rows : "1"}
        style={{ maxHeight: "450px" }}
        placeholder={placeholder}
        className={`form-control bg-light border-0 ${
          valid ? "" : "border border-danger"
        }`}
        value={value}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
      />
      <span
        className={`text-danger 
        ${valid !== undefined && valid ? "d-none" : ""}`}
      >
        The input can't be empty
      </span>
    </div>
  );
}

export default TextareaInput;
