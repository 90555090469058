import React, { useContext, useEffect, useState } from "react";
import CoachForm from "../../components/coaches/CoachForm";
import PanelTitle from "../../components/global/PanelTitle";
import { CoachesContext } from "../../context/CoachesContext";
import { ModalContext } from "../../context/ModalContext";
import CoachesTable from "../../components/coaches/CoachesTable";
import SortableCoachesTable from "../../components/coaches/SortableCoachesTable";

const AdminCoaches = () => {
  const [query, setQuery] = useState("");
  const [onlyHome, setOnlyHome] = useState(false);
  const [sortMode, setSortMode] = useState(false);

  const { coaches, setCoach, setCoaches, postCoach, getCoaches, deleteCoach } =
    useContext(CoachesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getCoaches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterHomeCoaches = () => [...coaches]
  .filter(({ show_home, file }) => show_home && file && file !== null)
  .filter(({ file }) => file.file_id !== null)
  .sort((a, b) => (a.order < b.order ? -1 : 1));

  const handleSave = () => {
    const coachesRender = filterHomeCoaches();
    coachesRender.forEach((coach) => postCoach(coach));
  };

  const handleCancel = () => {
    clearModal();
    setCoach(null);
  };

  const addCoach = () => {
    modalComponent(
      "Agregar Coach",
      <CoachForm idCoach="nueva" handleCancel={handleCancel} />
    );
  };

  const editCoach = (coach) => {
    modalComponent(
      "Editar Coach",
      <CoachForm idCoach={coach.instructor_id} handleCancel={handleCancel} />
    );
  };

  const confirmDelete = (coach) => {
    modalComponent(
      "¡Precaución!",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que quieres eliminar a la coach {coach.name}? Esta
          acción NO puede deshacerse.
        </p>
        <div className="row">
          <div className="col-6">
            <button className="btn text-muted w-100" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-danger w-100"
              onClick={() => deleteCoach(coach.instructor_id)}
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderCoaches = () => {
    if (Array.isArray(coaches)) {
      const homeCoaches = filterHomeCoaches();
      console.log(homeCoaches)
      if (sortMode) {
        return (
          <SortableCoachesTable
            coaches={homeCoaches}
            saveCoach={postCoach}
            modifier={setCoaches}
          />
        );
      }
      return (
        <CoachesTable
          query={query}
          handleEdit={editCoach}
          handleDelete={confirmDelete}
          coaches={onlyHome ? homeCoaches : coaches}
        />
      );
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Coaches" onClick={addCoach} />
      <div className="row align-items-center">
        <div className="col-12 col-md-8 mb-3">
          <input
            type="text"
            value={query}
            className="form-control"
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Buscar paquete por nombre..."
          />
        </div>
        <div className="col-6 col-md-2 mb-3">
          <button
            className="btn w-100 btn-outline-primary"
            onClick={() => setOnlyHome(!onlyHome)}
          >
            <i className="fa fa-eye me-2" />
            {onlyHome ? "Todas" : "Home"}
          </button>
        </div>
        <div className="col-6 col-md-2 mb-3">
          {onlyHome && <button
            className="btn w-100 btn-outline-primary"
            onClick={() => {
              if (sortMode) handleSave();
              setSortMode(!sortMode);
            }}
          >
            <i className={sortMode ? "fa fa-save me-1" : "fas fa-sort me-1"} />{" "}
            {sortMode ? "Guardar" : "Reordenar"}
          </button>}
        </div>
      </div>
      {renderCoaches()}
    </div>
  );
};

export default AdminCoaches;
