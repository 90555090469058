import moment from "moment";
import { downloadFileResponse } from "../../utils";
import React, { useContext, useState } from "react";
import AnaliticasService from "../../services/AnaliticasService";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import AnaliticasCoachRow from "../../components/coaches/AnaliticasCoachRow";

const AnaliticasInstructores = () => {
  const [inicio, setInicio] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [fin, setFin] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const { instructores, getInstructores } = useContext(AnaliticasContext);

  const handleReport = () => {
    AnaliticasService.getReporte(inicio, fin).then((response) => {
      downloadFileResponse(
        response,
        `Reporte_Instructores_${inicio}_${fin}`,
        "xlsx"
      );
    });
  };

  const handleDateChange = (startDate, endDate) => {
    getInstructores(startDate, endDate);
    setInicio(startDate);
    setFin(endDate);
  };

  const renderInstructores = () => {
    if (Array.isArray(instructores)) {
      if (instructores.length === 0) {
        return <p>No hay instructores registrados.</p>;
      }
      return instructores
        .filter((instructor) => instructor.name !== null)
        .map((instructores) => (
          <AnaliticasCoachRow
            key={instructores.instructor_id}
            instructor={instructores}
          />
        ));
    }
    return <div className="spinner-border" />;
  };

  return (
    <div className="container-fluid mt-3">
      <PanelTitleDate title="Instructores" callback={handleDateChange} />
      <div className="row">
        <div className="container-fluid">
          <div className="card me-3 shadow">
            <div className="row align-items-center pb-3">
              <div className="col-12 col-md-6">
                <h4>Alumnos por Instructor</h4>
              </div>
              <div className="col-12 col-md-6 text-end">
                <button
                  onClick={handleReport}
                  className="btn btn-outline-primary"
                >
                  <i className="far fa-file-excel me-1"></i> Descargar
                </button>
              </div>
            </div>
            <div className="row mx-0 bold bg-light border py-2 mb-2">
              <div className="col">Nombre</div>
              <div className="col">Clases</div>
              <div className="col">Reservados</div>
              <div className="col">Asistentes</div>
              <div className="col">Llenado (%)</div>
            </div>
            {renderInstructores()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasInstructores;
