import React, { useContext, useEffect, useState } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import { PurchasesContext } from "../../context/PurchasesContext";
import Pagination from "../../components/global/Pagination";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import { AuthContext } from "../../context/AuthContext";
import { PackagesContext } from "../../context/PackageContext";
import SearchSelect from "../../components/common/SearchSelect";

const AdminPurchases = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [packageId, setPackageId] = useState("");

  const { spinner, purchases, getPurchases, downloadPurchases } = useContext(PurchasesContext);

  const { paquetes, getPaquetes } = useContext(PackagesContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    getPaquetes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && !spinner) {
      getPurchases(startDate, endDate, {
        page: 1,
        status,
        query,
        class_package_id: packageId,
      });
      setPage(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && !spinner) {
      fetchPurchases();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, packageId, page, query]);

  const fetchPurchases = () => {
    getPurchases(startDate, endDate, {
      page,
      status,
      query,
      class_package_id: packageId,
    });
  };

  const setDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleDownload = () => {
    downloadPurchases(startDate, endDate, {
      page,
      status,
      query,
      excel: true,
      class_package_id: packageId,
    });
  };

  const renderPaquetes = () => {
    if (Array.isArray(paquetes)) {
      const defaultOption = {
        class_package_id: '',
        title: 'Todos'
      }

      return(
        <SearchSelect
          items={[defaultOption, ...paquetes ]}
          itemText="title"
          itemValue={`class_package_id`}
          placeholderText={`Seleccionar Paquete...`}
          modifier={(selectData) => setPackageId(selectData.value)}
        />
      )
    }
  };

  return (
    <div className="container-fluid h-100">
      <PanelTitleDate title="Pagos" callback={setDates} />
      <div className="container-fluid px-0">
        <input
          type="text"
          value={query}
          className="form-control mb-3"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar por nombre o correo electrónico..."
        />
      </div>
      <div className="card no-scale p-3 mb-3 shadow-sm">
        <div className="row align-items-center mb-3">
          <div className="col-12 col-md-6">
            <button
              className={`btn btn-sm btn-${
                status === "" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("")}
            >
              Todo
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status)
                  ? status.includes("active")
                    ? "primary"
                    : "light"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["active", "completed"])}
            >
              Activos
            </button>
            <button
              className={`btn btn-sm btn-${
                status === "cancelled" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("cancelled")}
            >
              Cancelados
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status)
                  ? status.includes("failed")
                    ? "primary"
                    : "light"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["pending", "failed"])}
            >
              Fallidos
            </button>
          </div>
          <div className="col-6 col-md-4">
            {renderPaquetes()}
          </div>
          <div className="col-6 col-md-2">
            <button
              onClick={handleDownload}
              className="btn w-100 btn-outline-dark"
            >
              <i className="fa me-2 fa-file-excel"></i> Descargar
            </button>
          </div>
        </div>
        <PurchasesTable purchases={purchases} user={user} />
        <Pagination setPage={setPage} page={page} />
      </div>
    </div>
  );
};

export default AdminPurchases;
