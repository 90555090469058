import React, { useState } from "react";
import SortableHeader from "../customers/SortableHeader";
import CoachRow from "./CoachRow";

const CoachesTable = ({ query, coaches, handleEdit, handleDelete }) => {
  const [sortCol, setSortCol] = useState("order");
  const [direction, setDirection] = useState("ASC");

  const renderCoaches = () => {
    if (Array.isArray(coaches)) {
      let coachesRender = coaches;
      if (query !== "") {
        coachesRender = coachesRender.filter(
          (coach) =>
            String(coach.name).toLowerCase().includes(query) ||
            String(coach.last_name).toLowerCase().includes(query)
        );
      }
      if (coachesRender.length === 0) return <p>No hay coaches registrados.</p>;
      coachesRender = coachesRender.sort((a, b) => {
        if(!isNaN(parseInt(a[sortCol]))) {
          return parseInt(a[sortCol]) > parseInt(b[sortCol])
          ? direction === "DESC"
            ? -1
            : 1
          : direction === "DESC"
          ? 1
          : -1
        }
        return String(a[sortCol]) > String(b[sortCol])
        ? direction === "DESC"
          ? -1
          : 1
        : direction === "DESC"
        ? 1
        : -1
      });
      return coachesRender.map((coach) => (
        <CoachRow
          key={coach.instructor_id}
          coach={coach}
          editCoach={handleEdit}
          deleteCoach={handleDelete}
        />
      ));
    }
  };

  return (
    <div className="card shadow">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className="bg-light bold">
              <SortableHeader
                column="name"
                label="Nombre"
                selected={sortCol}
                setSort={setSortCol}
                direction={direction}
                setDirection={setDirection}
              />
              <td>Instagram</td>
              <td>Cumpleaños</td>
              <td>Mostrar en Home</td>
              <SortableHeader
                column="order"
                label="Orden"
                selected={sortCol}
                setSort={setSortCol}
                direction={direction}
                setDirection={setDirection}
              />
              <td>
                <i className="fa fa-star me-1" /> Online
              </td>
              <td>
                <i className="fa fa-star me-1" /> Presencial
              </td>
              <td>Acciones</td>
            </tr>
          </thead>
          <tbody>{renderCoaches()}</tbody>
        </table>
      </div>
    </div>
  );
};
export default CoachesTable;
