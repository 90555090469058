import { useEffect, useContext } from "react";
import DynamicForm from "../global/DynamicForm";
import { MilestoneBenefitsContext } from "../../context/MilestoneBenefitsContext";
import { MilestonesContext } from "../../context/MilestonesContext";

const MilestoneBenefitForm = ({ milestone_benefit_id, handleCancel }) => {
  const { milestones, getMilestones } = useContext(MilestonesContext);
  const {
    milestone_benefit,
    saveMilestoneBenefit,
    createMilestoneBenefit,
    getSingleMilestoneBenefit,
    setPropertyMilestoneBenefit,
  } = useContext(MilestoneBenefitsContext);

  useEffect(() => {
    if (isNaN(parseInt(milestone_benefit_id))) {
      createMilestoneBenefit();
    } else {
      getSingleMilestoneBenefit(milestone_benefit_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectFields = [
    {
      type: "milestone",
      items: milestones,
      labelKey: "name",
      key: "milestone_id",
      valueKey: "milestone_id",
      getItems: getMilestones,
    },
    {
      key: "type",
      labelKey: "label",
      valueKey: "value",
      getItems: () => {},
      items: [
        { value: "", label: "Seleccionar..."},
        { value: "file", label: "Archivo / PDF" },
        { value: "video", label: "Video (URL)" },
        { value: "url", label: "URL Externa" },
      ],
    },
  ];

  const excludeFields = ["createdAt", "updatedAt", "deletedAt"];

  if(milestone_benefit?.type === "file") {
    excludeFields.push("src");
  }

  return (
    <DynamicForm
      exclude={excludeFields}
      object={milestone_benefit}
      selectFields={selectFields}
      handleCancel={handleCancel}
      objectType="milestone_benefit"
      saveAction={saveMilestoneBenefit}
      modifier={setPropertyMilestoneBenefit}
    />
  );
};

export default MilestoneBenefitForm;
