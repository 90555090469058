import React, { useContext, useEffect } from "react";
import HeaderRow from "../../components/global/HeaderRow";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import { PackagesContext } from "../../context/PackageContext";
import ConfirmDelete from "../../components/global/ConfirmDelete";
import PaqueteEspecialRow from "../../components/paquetes/PaqueteEspecialRow";

const AdminEspeciales = () => {
  const { paquetes, getPaquetesEspecialesAdmin, deletePaquete, clearPaquetes } =
    useContext(PackagesContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPaquetesEspecialesAdmin();
    return clearPaquetes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmDelete = (paquete) => {
    modalComponent(
      "Precaución",
      <ConfirmDelete
        message={`¿Estás segura que deseas eliminar el paquete ${paquete.title}?`}
        handleCancel={() => deletePaquete(paquete.class_package_id)}
      />
    );
  };

  const renderPaquetesEspeciales = () => {
    if (paquetes && paquetes !== null) {
      if (paquetes.length === 0) {
        return <p className="p-2">No hay paquetes especiales registrados.</p>;
      }
      return paquetes.map((paquete) => (
        <PaqueteEspecialRow
          key={paquete.class_package_id}
          paquete={paquete}
          confirmDelete={confirmDelete}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Paquetes Especiales" hideButton />
      <HeaderRow headers={["Título", "Fecha y Hora", "Capacidad", "Compras"]} />
      {renderPaquetesEspeciales()}
    </div>
  );
};

export default AdminEspeciales;
