
import {
  SET_MILESTONEBENEFIT,
  CREATE_MILESTONEBENEFIT,
  MILESTONEBENEFITS_RECEIVED,
  SET_PROPERTY_MILESTONEBENEFIT,
} from "../types/milestone_benefits";

const schema = {

}

const milestoneBenefitsReducer = (state, { type, payload }) => {
  switch (type) {
    case MILESTONEBENEFITS_RECEIVED:
      return { ...state, milestone_benefits: payload };
    case SET_MILESTONEBENEFIT:
      return { ...state, milestone_benefit: payload };
    case CREATE_MILESTONEBENEFIT:
      return { ...state, milestone_benefit: schema };
    case SET_PROPERTY_MILESTONEBENEFIT: {
      const { key, value } = payload;
      const milestone_benefit = { ...state.milestone_benefit };
      milestone_benefit[key] = value;
      return { ...state, milestone_benefit };
    }
    default:
      return { ...state};
  }
};

export default milestoneBenefitsReducer;
