import React from "react";
import { Router } from "@reach/router";
import AdminClientes from "./admin/AdminClientes";
import AdminPaquetes from "./admin/AdminPaquetes";
import AdminPaqueteForm from "./admin/AdminPaqueteForm";
import AdminClases from "./admin/AdminClases";
import AdminSesiones from "./admin/AdminSesiones";
import AdminAsistentes from "./admin/AdminAsistentes";
import AdminLocations from "./admin/AdminLocations";
import AdminCoaches from "./admin/AdminCoaches";
import AdminSingleUsuario from "./admin/AdminSingleUsuario";
import AdminCategories from "./admin/AdminCategories";
import AdminSingleCategory from "./admin/AdminSingleCategory";
import AdminHome from "./admin/AdminHome";
import AdminClassTypes from "./admin/AdminClassTypes";
import AdminDescuentos from "./admin/AdminDescuentos";
import AdminEspeciales from "./admin/AdminEspeciales";
import AdminAsistentesEspeciales from "./admin/AdminAsistentesEspeciales";
import AdminVideos from "./admin/AdminVideos";
import AdminVideoForm from "./admin/AdminVideoForm";
import AdminCustomerForm from "./admin/AdminCustomerForm";
import AdminUsuarios from "./admin/AdminUsuarios";
import AdminInvoices from "./admin/AdminInvoices";
import AdminPurchases from "./admin/AdminPurchases";
import AdminOnline from "./admin/AdminOnline";
import AdminPresenciales from "./admin/AdminPresenciales";
import AdminPages from "./admin/AdminPages";
import AdminPageForm from "./admin/AdminPageForm";
import AdminComunidad from "./admin/AdminComunidad";
import AdminCart from "./admin/AdminCart";
import AdminVideoRatings from "./admin/AdminVideoRatings";
import AdminClassesRatings from "./admin/AdminClassesRatings";
import AdminProps from "./admin/AdminProps";
import AdminMuscles from "./admin/AdminMuscles";
import AdminTestimonios from "./admin/AdminTestimonios";
import AdminSingleTestimonio from "./admin/AdminSingleTestimonio";
import AdminFrequentQuestions from "./admin/AdminFrequentQuestions";
import AdminSingleFrequentQuestion from "./admin/AdminSingleFrecuentQuestion";
import AdminBranches from "./admin/AdminBranches";
import AdminAjustes from "./admin/AdminAjustes";
import AdminMilestones from "./admin/AdminMilestones";
import AdminSingleMilestone from "./admin/AdminSingleMilestone";

const Admin = () => {
  return (
    <Router>
      <AdminAjustes path="/ajustes"/>
      <AdminClientes path="/customers" />
      <AdminCustomerForm path="/customer/:customer_id/edit" />
      <AdminSingleUsuario path="/customer/:customer_id" />
      <AdminPaquetes path="/paquetes" />
      <AdminPaqueteForm path="/paquetes/:package_class_id" />
      <AdminClases path="/clases/*" default />
      <AdminSesiones path="/asistentes" />
      <AdminAsistentes path="/asistentes/:single_class_id" />
      <AdminLocations path="/ubicaciones" />
      <AdminCoaches path="/coaches" />
      <AdminCategories path="/categorias" />
      <AdminSingleCategory path="/categorias/:class_category_id" />
      <AdminClassTypes path="/tipos" />
      <AdminHome path="/home" />
      <AdminDescuentos path="/descuentos" />
      <AdminEspeciales path="/especiales" />
      <AdminAsistentesEspeciales path="/especiales/:class_package_id" />
      <AdminVideos path="/videos" />
      <AdminVideoForm path="/videos/:video_id" />
      <AdminPurchases path="/orders" />
      <AdminUsuarios path="/users" />
      <AdminInvoices path="/subscriptions" />
      <AdminLocations path="/locations" />
      <AdminOnline path="/online" />
      <AdminPresenciales path="/presenciales" />
      <AdminPages path="/pages" />
      <AdminPageForm path="/pages/:page_id" />
      <AdminComunidad path="/comunidad" />
      <AdminCart path="/carritos" />
      <AdminProps path="/props" />
      <AdminMuscles path="/muscles" />
      {/* Milestones */}
      <AdminMilestones path="/milestones" />
      <AdminSingleMilestone path="/milestones/:milestone_id" />


      <AdminVideoRatings path="/videos/ratings" />
      <AdminClassesRatings path="/single_classes/ratings" />
      <AdminTestimonios path="/testimonios" />
      <AdminSingleTestimonio path="/testimonios/:testimonial_id" />
      <AdminFrequentQuestions path= "/frequent_questions"/>
      <AdminSingleFrequentQuestion path= "/frequent_questions/:question_id"/>
      <AdminBranches path= "/branches"/>
    </Router>
  );
};

export default Admin;
