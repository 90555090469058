import React, { useContext, useEffect, useState } from "react";
import { TestimoniosContext } from "../../context/TestimoniosContext";
import { CustomerContext } from "../../context/CustomerContext";
import AdjuntosService from "../../services/AdjuntosService";
import PanelTitle from "../../components/global/PanelTitle";
import { S3_ENDPOINT } from "../../utils";
import CreatableSelect from "react-select/creatable";
import ReactSwitch from "react-switch";
import { navigate } from "@reach/router";

const grayImg =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIwIiBoZWlnaHQ9IjMyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4=";

const AdminSingleTestimonio = ({ testimonial_id }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const editMode = testimonial_id !== "new";

  const {
    testimonio,
    testimonio_form,
    getAllTestimonios,
    getTestimonioById,
    postTestimonio,
    updateTestimonio,
    setTestimonioFormProperty,
    clearForm,
    updateTestimoniosForm,
  } = useContext(TestimoniosContext);

  const { customers, customer, getAllCustomers, getCustomer, clearCustomer } =
    useContext(CustomerContext);

  useEffect(() => {
    getAllCustomers({ page: 1 });
    if (editMode) getTestimonioById(testimonial_id);

    return () => {
      clearCustomer();
      clearForm();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editMode) {
      setFormData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonio]);

  useEffect(() => {
    const customer_id = testimonio_form.customer_id;
    if (customer_id) {
      getCustomer(customer_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonio_form.customer_id]);

  useEffect(() => {
    if (editMode && firstLoad === false) {
      setTestimonioFormProperty("customer_image_src", grayImg);
      setTestimonioFormProperty("customer_image_file", null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonio_form.customer_name]);

  useEffect(() => {
    const opts = customers?.map((customer) => {
      const name = customer.name ? customer.name : "";
      const last_name = customer.last_name ? customer.last_name : "";
      const label = `${name} ${last_name}`;

      return {
        label,
        value: customer.customer_id,
      };
    });

    setTestimonioFormProperty("customer_opts", opts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    const src = `${S3_ENDPOINT}/${customer?.file?.name}/${customer?.file?.type}`;

    if (editMode && firstLoad === false) {
      if (customer?.file?.name) {
        setTestimonioFormProperty("customer_image_src", src);
      }
    }

    if (editMode === false && customer?.file?.name) {
      setTestimonioFormProperty("customer_image_src", src);
    }

    if (customer) setFirstLoad(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const setFormData = () => {
    const customerFile = testimonio.customer_file;
    const customerFileSrc = `${S3_ENDPOINT}/${customerFile?.name}.${customerFile?.type}`;

    const data = {
      customer_image_src: customerFile ? customerFileSrc : grayImg,
      customer_image_file: testimonio.customer_file_id,
      customer_name: testimonio.customer_name,
      customer_id: testimonio.customer_id,
      description: testimonio.description,
      category: testimonio.category,
      rating: testimonio.rating,
      show_home: testimonio.show_home,
      order: testimonio.order,
    };

    updateTestimoniosForm(data);
  };

  const searchCustomers = (name) => {
    getAllCustomers({ query: name, page: 1, setLimit: true });
  };

  const getFormData = () => {
    const {
      customer_name,
      customer_id,
      category,
      description,
      rating,
      order,
      show_home,
    } = testimonio_form;

    const data = {
      customer_name,
      customer_id,
      category,
      description,
      rating,
      order,
      show_home,
    };

    return data;
  };

  const getFilesData = () => {
    const { customer_image_file } = testimonio_form;
    const filesData = [{ key: "customer_file_id", file: customer_image_file }];
    return filesData;
  };

  const uploadFiles = async (filesData) => {
    const promises = [];
    const uploadedFiles = {};

    filesData.forEach((obj) => {
      promises.push(
        new Promise(async (resolve, reject) => {
          // if editing mode set the current file and it doesn't change, the fun wont upload a new file
          if (typeof obj.file === "number") {
            uploadedFiles[obj.key] = obj.file;
          } else {
            if (obj.file) {
              const formData = new FormData();
              formData.append("file", obj.file);
              const response = await AdjuntosService.postAdjunto(formData);
              const file_id = response.data.file_id;
              uploadedFiles[obj.key] = file_id;
            } else {
              uploadedFiles[obj.key] = null;
            }
          }

          resolve();
        })
      );
    });

    await Promise.all(promises);
    return uploadedFiles;
  };

  const viewTitle =
    testimonial_id === "new" ? "Crear Testimonio" : "Editar Testimonio";

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = getFormData();
    const filesData = getFilesData();
    const uploadedFiles = await uploadFiles(filesData);

    const testimonioData = {
      ...data,
      ...uploadedFiles,
    };

    if (
      testimonioData.customer_id &&
      testimonioData.customer_file_id === null
    ) {
      testimonioData.customer_file_id = customer.file_id;
    }

    if (editMode) {
      updateTestimonio(testimonial_id, testimonioData).then((res) => {
        getAllTestimonios();
        navigate("/myadmin/testimonios");
      });
    } else {
      postTestimonio(testimonioData).then((res) => {
        getAllTestimonios();
        navigate("/myadmin/testimonios");
      });
    }
  };

  return (
    <div className="contiainer-fluid px-2 h-100">
      <PanelTitle title={viewTitle} hideButton />

      <form onSubmit={handleSubmit}>
        <div className="card shadow">
          <div className="row">
            <div
              className="col-12 col-xl-4 mb-2 position-relative flex-wrap"
              style={{
                minHeight: "400px",
              }}
            >
              <label className=" d-block bold">Imagen del Testimonio</label>
              <img
                style={{
                  height: "400px",
                  width: "300px",
                  objectFit: "contain",
                  marginBottom: "50px",
                }}
                alt=""
                src={testimonio_form["customer_image_src"]}
                className="image-input bg-light border mx-auto d-block"
              />
              <input
                className="form-control position-absolute bottom-0 mx-auto text-center"
                style={{ width: "calc(100% - 24px)" }}
                type="file"
                accept="image/*"
                onChange={(event) => {
                  const reader = new FileReader();
                  const image = event.target.files[0];

                  if (image) {
                    reader.onload = (e) => {
                      const src = e.target.result;
                      setTestimonioFormProperty("customer_image_src", src);
                      setTestimonioFormProperty("customer_image_file", image);
                    };

                    reader.readAsDataURL(image);
                  }
                }}
              />
            </div>

            <div className="col-12 col-xl-8">
              <div className="row">
                <div className="col-12">
                  <label className="bold">Nombre del Usuario</label>

                  <CreatableSelect
                    className="mb-2"
                    value={{
                      label: testimonio_form["customer_name"],
                      value: testimonio_form["customer_id"],
                    }}
                    options={testimonio_form["customer_opts"]}
                    onInputChange={searchCustomers}
                    onChange={(opt) => {
                      const newId = opt.value;
                      const newValue = opt.label;

                      if (isNaN(newId)) {
                        setTestimonioFormProperty("customer_id", null);
                      } else {
                        setTestimonioFormProperty("customer_id", newId);
                      }
                      setTestimonioFormProperty(
                        "customer_name",
                        newValue.trim()
                      );
                    }}
                  />
                </div>

                <div className="col-12 mb-2">
                  <label className="bold">Categoria</label>
                  <select
                    value={testimonio_form["category"]}
                    className={`form-control`}
                    onChange={(event) =>
                      setTestimonioFormProperty("category", event.target.value)
                    }
                  >
                    <option value={"online"}>Clases en Linea</option>
                    <option value={"live"}>Clases Presenciales</option>
                  </select>
                </div>

                <div className={`col-6 mb-2`}>
                  <label className="bold">Calificación</label>
                  <input
                    type="number"
                    max={5}
                    min={1}
                    className={`form-control`}
                    value={testimonio_form["rating"]}
                    onChange={(event) => {
                      let currentValue = parseInt(event.target.value);
                      if (currentValue > 5) {
                        currentValue = 5;
                      }

                      if (currentValue < 1) {
                        currentValue = 1;
                      }

                      setTestimonioFormProperty("rating", currentValue);
                    }}
                  />
                </div>

                <div
                  className={`col-6 mb-2 d-flex position-relative 
                  align-items-center justify-content-between`}
                >
                  <label className="me-2 bold">Mostrar en Home</label>
                  <ReactSwitch
                    checked={testimonio_form["show_home"]}
                    onChange={(checked) => {
                      setTestimonioFormProperty("show_home", checked);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <label className="bold">Testimonio</label>
              <textarea
                value={testimonio_form["description"]}
                className={`form-control`}
                style={{
                  height: "200px",
                }}
                onChange={(event) =>
                  setTestimonioFormProperty("description", event.target.value)
                }
              />
            </div>

            <div className="col-12 mt-3 text-end">
              <button
                type="button"
                onClick={() => navigate("/myadmin/testimonios")}
                className="btn btn-link text-secondary"
              >
                Cancelar
              </button>

              <button type="submit" className="btn btn-primary ">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminSingleTestimonio;
