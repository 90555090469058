import React from "react";

const AnaliticasCoachRow = ({ instructor }) => {
  return (
    <div className="row my-2">
      <div className="col">{instructor.name}</div>
      <div className="col">{instructor.single_classes}</div>
      <div className="col">{instructor.booked}</div>
      <div className="col">{instructor.attended}</div>
      <div className="col">
        {((instructor.attended / instructor.booked) * 100).toFixed(2)}%
      </div>
    </div>
  );
};

export default AnaliticasCoachRow;
