import React, { useContext, useEffect } from "react";
import { FrequentQuestionsContext } from "../../context/FrequentQuestionsContext";
import PanelTitle from "../../components/global/PanelTitle";
import { navigate } from "@reach/router";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";

const AdminFrequentQuestions = () => {
  const { allQuestions, getFrequentQuestions, deleteFrequentQuestion } =
    useContext(FrequentQuestionsContext);

  const { modalComponent, clearModal } = useContext(ModalContext);

  useEffect(() => {
    getFrequentQuestions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createQuestion = () => {
    navigate("/myadmin/frequent_questions/new");
  };

  const handleEditQuestion = (question_id) => {
    navigate("/myadmin/frequent_questions/" + question_id);
  };

  const handleDeleteQuestion = (question_id) => {
    modalComponent(
      "Eliminar Pregunta Frecuente",
      <div className="row">
        <p>¿Esta seguro de eliminar esta Pregunta Frecuente?</p>

        <div className="col-12">
          <button
            className="btn btn-link text-secondary w-50"
            onClick={() => clearModal()}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary w-50"
            onClick={() =>
              deleteFrequentQuestion(question_id, getFrequentQuestions)
            }
          >
            Eliminar
          </button>
        </div>
      </div>
    );
  };

  const renderFrequentQuestions = () => {
    if (allQuestions?.length === 0) {
      return <p className="mt-3 ps-3">No hay preguntas frecuentes registradas</p>;
    }
    if (Array.isArray(allQuestions))
      return allQuestions.map((obj) => {
        return (
          <div
            key={obj.frequent_question_id}
            className="row mx-auto w-100 border py-2"
          >
            <div className="col-5">{obj.question}</div>
            <div className="col-4 text-center">
              {moment(obj.updatedAt).format("DD MMM YYYY")}
            </div>
            <div className="col-3 text-center">
              <button
                className="btn btn-sm btn-outline-dark me-2"
                onClick={() => handleEditQuestion(obj.frequent_question_id)}
              >
                <i className="fa fa-edit"></i>
              </button>

              <button
                className="btn btn-sm btn-outline-danger me-2"
                onClick={() => handleDeleteQuestion(obj.frequent_question_id)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          </div>
        );
      });
  };

  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle title="Preguntas Frecuentes" onClick={createQuestion} />
      <div className="card p-3 shadow-sm no-scale">
        <div
          className="row w-100 mx-auto border bg-light align-items-center"
          style={{ height: "45px" }}
        >
          <div className="col-5 bold" style={{ height: "max-content" }}>
            Pregunta
          </div>
          <div
            className="col-4 bold text-center"
            style={{ height: "max-content" }}
          >
            Ultima Modificación
          </div>

          <div
            className="col-3 bold text-center"
            style={{ height: "max-content" }}
          >
            Acciones
          </div>
        </div>
        {renderFrequentQuestions()}
      </div>
    </div>
  );
};

export default AdminFrequentQuestions;
