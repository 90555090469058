import React from "react";
import InvoiceRow from "./InvoiceRow";

const InvoicesTable = ({ user, invoices, customer_id }) => {
  const renderInvoices = () => {
    if (Array.isArray(invoices)) {
      if (invoices.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay cargos registradas.</td>
          </tr>
        );
      }

      invoices.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
      
        return dateB - dateA;
      });

      return invoices.map((invoice) => (
        <InvoiceRow
          key={invoice.invoice_id}
          user={user}
          invoice={invoice}
          customer_id={customer_id}
        />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table border" style={{ minHeight: '150px' }}>
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>#Compra</td>
            <td>Fecha</td>
            <td>Paquete</td>
            {!customer_id && <td>Cliente</td>}
            <td>Total</td>
            <td>Estado</td>
            <td>Sig. Cargo</td>
            <td colSpan={2}>Pago</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderInvoices()}</tbody>
      </table>
    </div>
  );
};

export default InvoicesTable;
