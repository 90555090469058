import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import ConfirmDelete from "../../components/global/ConfirmDelete";
import { WaitlistsContext } from "../../context/WaitlistsContext";
import { SingleClassContext } from "../../context/SingleClassContext";
import AgregarAsistente from "../../components/clases/AgregarAsistente";
import { ReservationsContext } from "../../context/ReservationsContext";
import AsistentesTable from "../../components/asistentes/AsistentesTable";
import SingleClassData from "../../components/single_classes/SingleClassData";

const AdminAsistentes = ({ single_class_id }) => {
  const [interval, setInterval] = useState(null);
  const [notifying, setNotifying] = useState(false);
  const { clase, clearClase, postPayment, getAsistentes, updateGuestName } =
    useContext(SingleClassContext);
  const { user } = useContext(AuthContext);
  const { postAttend } = useContext(ReservationsContext);
  const { eliminarReservacion } = useContext(ReservationsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { waitlist, getSingleClassWaitlist } = useContext(WaitlistsContext);

  const [view, setView] = useState("reservas");

  useEffect(() => {
    fetchAsistentes();
    return () => {
      clearClase();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_class_id]);

  useEffect(() => {
    if (notifying) {
      const current = window.setInterval(fetchAsistentes, 3000);
      setInterval(current);
    } else if (interval !== null) {
      window.clearInterval(interval);
      setInterval(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifying]);

  const fetchAsistentes = (notified) => {
    getSingleClassWaitlist(single_class_id);
    getAsistentes(single_class_id);
    if (notified) {
      setNotifying(notified);
    }
  };

  const addAsistente = () => {
    modalComponent(
      "Agregar Asistente",
      <AgregarAsistente single_class_id={single_class_id} />
    );
  };

  const confirmCancel = (customer) => {
    modalComponent(
      "¿Cancelar reservacion?",
      <ConfirmDelete
        message={`¿Estás segura que deseas cancelar la reservacion de ${customer.name}?`}
        handleCancel={clearModal}
        handleDelete={() =>
          eliminarReservacion(customer.class_reservation_id, () =>
            getAsistentes(single_class_id)
          )
        }
      />
    );
  };

  const renderClase = () => {
    if (clase && clase !== null) {
      return (
        <SingleClassData
          single_class={clase}
          notifying={notifying}
          setNotifying={setNotifying}
          handleCallback={fetchAsistentes}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderAsistentes = () => {
    if (clase && clase !== null && view === "reservas") {
      return clase.asistentes;
    }
    if (view === "waitlist") {
      return waitlist.map(({ customer }) => customer);
    }
    return [];
  };

  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle title="Clase Presencial" onClick={addAsistente} />
      <div className="card p-3 shadow-sm no-scale">{renderClase()}</div>
      <div className="btn-group w-100 mt-3">
        <div
          onClick={() => setView("reservas")}
          className={`btn btn-${
            view === "reservas" ? "primary" : "outline-primary"
          }`}
        >
          Reservas ({clase?.asistentes?.length})
        </div>
        <div
          onClick={() => setView("waitlist")}
          className={`btn btn-${
            view === "waitlist" ? "primary" : "outline-primary"
          }`}
        >
          Lista de Espera ({waitlist?.length})
        </div>
      </div>
      <AsistentesTable
        asistentes={renderAsistentes()}
        is_waitlist={view === "waitlist"}
        single_class_id={single_class_id}
        updateGuestName={updateGuestName}
        handleCallback={fetchAsistentes}
        confirmCancel={confirmCancel}
        postPayment={postPayment}
        postAttend={postAttend}
        notifying={notifying}
        single_class={clase}
        user={user}
      />
    </div>
  );
};

export default AdminAsistentes;
