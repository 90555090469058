import moment from "moment";
import React from "react";

const VideoInfo = ({ video }) => {
  const renderTag = () => {
    if (video.tags.length > 0) {
      return video.tags[0].name;
    }
  };

  const renderType = () => {
    if (video.video_type !== null) {
      return video.video_type.name;
    }
  };

  const renderCategory = () => {
    if (video.class_type !== null) {
      if (video.class_type.class_category !== null) {
        return video.class_type.class_category.name;
      }
    }
  };

  const renderClase = () => {
    if (video.class_type !== null) {
      return video.class_type.name;
    }
  };

  const renderCoach = () => {
    if (video.instructor !== null) {
      return video.instructor.name + " " + video.instructor.last_name;
    }
  };

  // const renderPlaybacks = () => {
  //   if (Array.isArray(video.playbacks)) {
  //     return video.playbacks.length;
  //   }
  //   return video.video_playbacks;
  // };

  return (
    <div className="card bg-light br-0 shadow no-scale">
      <div className="row mb-3">
        <div className="col-12 col-md-4">
          <div className="row mb-3">
            <div className="col-6">
              <span className="text-primary">
                <i className="fa fa-user-circle me-2" />
                {renderCoach()}
              </span>
            </div>
            <div className="col-6 text-end">
              <span className="text-primary">
                {moment(
                  video.available_at !== null
                    ? video.available_at
                    : video.createdAt
                ).format("DD MMM YYYY")}
              </span>
            </div>
          </div>
          <p className="bold mb-2">Props</p>
          <p className="small">{video.description}</p>
        </div>
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="row mb-3">
                <div className="col-4 bold">Clase</div>
                <div className="col-6">
                  <span className="badge bg-secondary-light text-dark">
                    {renderClase()}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 bold">Categoría</div>
                <div className="col-8">
                  {" "}
                  <span className="badge bg-secondary-light text-dark">
                    {renderCategory()}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row mb-3">
                <div className="col-4 bold">Impacto</div>
                <div className="col-6">
                  {" "}
                  <span className="badge bg-secondary-light text-dark">
                    {renderType()}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4 bold">Duración</div>
                <div className="col-6">
                  {" "}
                  <span className="badge bg-secondary-light text-dark">
                    {video.duration}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4 bold">Objetivo</div>
                <div className="col-6">
                  {" "}
                  <span className="badge bg-secondary-light text-dark">
                    {renderTag()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*
        <div className="col-4 bold">
          <i className="fa fa-eye" /> Vistas
        </div>
        <div className="col-8">{renderPlaybacks()}</div>
        <div className="row mb-3">
          <div className="col-4 bold">
            <i className="fa fa-heart" /> Favs
          </div>
          <div className="col-8">{video.favorites.length}</div>
        </div>
        <div className="row mb-3">
          <div className="col-4 bold">
            <i className="fa fa-star" /> Rating
          </div>
          <div className="col-8">
            {video.class_stars_average === null
              ? "Sin calificar"
              : parseFloat(video.class_stars_average).toFixed(2)}
          </div>
        </div>

        */}
      </div>
    </div>
  );
};
export default VideoInfo;
