import React from "react";
import CoachCard from "./CoachCard";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

const CoachTile = SortableElement(({ index, coach }) => (
  <CoachCard coach={coach} index={index} draggable />
));

const CoachesList = SortableContainer(({ coaches }) => {
  return (
    <div>
      {coaches.map((coach, index) => (
        <CoachTile key={coach.instructor_id} index={index} coach={coach} />
      ))}
    </div>
  );
});

const SortableCoachesTable = ({ coaches, modifier }) => {

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const coachesRender = JSON.parse(JSON.stringify([...coaches]));
    const coachesArray = JSON.parse(
      JSON.stringify(arrayMove(coachesRender, oldIndex, newIndex))
    ).map((coach, index) => ({ ...coach, order: index + 1 }));
    modifier(coachesArray);
  };

  return (
    <div className="card shadow">
      <div className="row border mx-0 p-2 bg-light bold">
        <div className="col" />
        <div className="col">Nombre</div>
        <div className="col">Instagram</div>
        <div className="col">
          <i className="fa fa-star" /> Online
        </div>
        <div className="col">
          <i className="fa fa-star" /> Presencial
        </div>
        <div className="col">Acciones</div>
      </div>
      <CoachesList coaches={coaches} onSortEnd={onSortEnd} />
    </div>
  );
};

export default SortableCoachesTable;
