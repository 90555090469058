import React, { useEffect, useState } from "react";
import ReactSwitch from "react-switch";
import { getValue } from "../../utils";

const VideoPropFormRow = ({ prop, video, modifier }) => {
  const [prevProps, setPrevProps] = useState(null);
  const [videoProp, setVideoProp] = useState(null);

  useEffect(() => {
    if (prevProps !== video.video_props) {
      setPrevProps(video.video_props);
      findVideoProp();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  const findVideoProp = () => {
    if (Array.isArray(video.video_props)) {
      const index = video.video_props.findIndex(
        ({ prop_id }) => prop_id === prop.prop_id
      );
      if (index !== -1) setVideoProp(video.video_props[index]);
    }
  };

  const renderForm = () => {
    if (videoProp !== null) {
      return [
        <div key="amount" className="col-2">
          <input
            type="text"
            className="form-control p-1"
            value={getValue(videoProp, "amount")}
            onChange={(e) => modifier(prop.prop_id, "amount", e.target.value)}
          />
        </div>,
        <div key="required" className="col-2">
          <ReactSwitch
            checked={getValue(videoProp, "required", "boolean")}
            onChange={(checked) => modifier(prop.prop_id, "required", checked)}
          />
        </div>,
      ];
    }
  };

  const checked = videoProp !== null;

  return (
    <div className="row py-2 border-bottom align-items-center">
      <div className="col-1">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => modifier(prop.prop_id, "checked", e.target.value)}
        />
      </div>
      <div className="col-7">{getValue(prop, "name")}</div>
      {renderForm()}
    </div>
  );
};

export default VideoPropFormRow;
