import React, { useContext, useEffect } from "react";
import { StreaksContext } from "../../context/StreaksContext";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../global/PanelTitle";
import HeaderRow from "../global/HeaderRow";
import StreakForm from "./StreakForm";
import moment from "moment";

const StreaksTable = ({ customer_id }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { streaks, setStreak, getCustomerStreaks } = useContext(StreaksContext);

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const fetchData = () => {
    getCustomerStreaks(customer_id);
  };

  const handleEdit = (streak) => {
    setStreak(streak);
    modalComponent(
      "Editar Streak",
      <StreakForm handleCancel={clearModal} handleCallback={fetchData} />
    );
  };

  const renderStreaks = () => {
    if (Array.isArray(streaks)) {
      if (streaks.length === 0) {
        return <p>No hay streaks registrados.</p>;
      }
      return streaks.map((streak) => (
        <div key={streak.streak_id} className="row mx-0 py-2 border-bottom">
          <div className="col">#{streak.number}</div>
          <div className="col">
            {moment(streak.start_date).utc().format("DD MMM YYYY")}
          </div>
          <div className="col">
            {moment(streak.end_date).utc().format("DD MMM YYYY")}
          </div>
          <div className="col">
            <button
              onClick={() => handleEdit(streak)}
              className="btn btn-sm btn-outline-dark"
            >
              <i className="fa fa-edit" />
            </button>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="card p-3 shadow no-scale">
      <PanelTitle title="Streaks" hideButton />
      <HeaderRow headers={["Número", "Inicio", "Fin", "Acciones"]} />
      {renderStreaks()}
    </div>
  );
};

export default StreaksTable;
