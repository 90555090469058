import React, { useContext, useEffect, useRef, useState } from "react";
import { NotesContext } from "../../context/NotesContext";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";

const NotesOffcanvas = ({ handleCallback, customer_id }) => {
  const [editingNote, setEditingNote] = useState(null);
  const [noteToDelete, setNoteToDelete] = useState(null);

  const { note, notes, getNotesByCustomer, setPropertyNote, saveNote, deleteNote } =
    useContext(NotesContext);

  const { user } = useContext(AuthContext);

  const textareaRef = useRef();

  useEffect(() => {
    getNotesByCustomer(customer_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const user_id = user?.user_id;
    setPropertyNote("user_id", user_id);
    setPropertyNote("customer_id", customer_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, customer_id]);

  const handleCreateNote = () => {
    if(textareaRef.current) {
      saveNote(note, () => {
        handleCallback();
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
      });
    }
  
  };

  const handleUpdate = () => {
    saveNote(editingNote, () => {
      getNotesByCustomer(customer_id);
      setEditingNote(null);
    });
  };

  const renderNoteContent = (currentNote) => {
    const dateTime = moment(currentNote.createdAt).format("DD MMM YYYY HH:MM");

    if (currentNote.note_id === editingNote?.note_id) {
      return (
        <div className="row mt-2">
          <div className="col-12">
            <textarea
              style={{ height: "100px" }}
              className="form-control"
              value={editingNote.content}
              onChange={(event) =>
                setEditingNote({
                  ...editingNote,
                  content: event.target.value,
                })
              }
            />
          </div>

          <div className="col-6 mt-2">
            <button
              type="button"
              className="btn w-100 text-muted"
              onClick={() => setEditingNote(null)}
            >
              Cancelar
            </button>
          </div>

          <div className="col-6 mt-2">
            <button
              type="button"
              className="btn w-100 btn-primary"
              onClick={handleUpdate}
            >
              Guardar
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <>
          {currentNote.content}
          <div className="row w-100 mx-auto justify-content-end mt-2">
            {dateTime}
          </div>
        </>
      );
    }
  };

  const renderNotes = () => {
    if (notes?.length > 0) {
      return notes.map((note) => {
        const customer = note.user.customer;
        const authorName = `${customer.name} ${customer.last_name}`;

        return (
          <div key={note.note_id} className="col-12">
            <div className="card p-2 my-3">
              <div className="d-flex justify-content-between align-items-center w-100 bold">
                <span>
                  <i className="fas fa-user me-2 text-primary" />
                  {authorName}
                </span>

                <div className="dropdown">
                  <span
                    className="d-inline-block text-center"
                    style={{ width: "24px", height: "24px" }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fas fa-ellipsis-v" />
                  </span>

                  <ul className="dropdown-menu py-0">
                    <li>
                      <button
                        className="dropdown-item text-primary px-2 "
                        onClick={() => {
                          setEditingNote(note);
                        }}
                      >
                        <i className="fas fa-edit text-primary me-2" />
                        Editar
                      </button>
                    </li>

                    <li>
                      <button
                        className="dropdown-item text-danger px-2"
                        onClick={() => {
                          setNoteToDelete(note);
                        }}
                      >
                        <i className="fas fa-trash text-danger me-2" />
                        Eliminar
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {renderNoteContent(note)}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="card p-3 pb-1  my-3">
          <p style={{fontSize: '16px'}}>No se han registrado Notas para este cliente.</p>
        </div>
      );
    }
  };

  const renderDeleteAdvice = () => {
    const note_id = noteToDelete.note_id;
    const customer = noteToDelete.user.customer;
    const authorName = `${customer.name} ${customer.last_name}`;

    return (
      <div className="col-12">
        <div className="row">
          <p className="mb-0 mt-2">¿Esta seguro de eliminar la siguiente nota?</p>
          <p className="bold">Esta acción NO podrá deshacerse</p>
        </div>

        <div className="card p-2 my-3">
          <div className="d-flex justify-content-between align-items-center w-100 bold">
            <span>
              <i className="fas fa-user me-2 text-primary" />
              {authorName}
            </span>
          </div>
          {renderNoteContent(noteToDelete)}
        </div>


        <div className="row w-100 mx-auto">
          <button
            className="btn text-muted w-50"
            onClick={() => {
              setNoteToDelete(null);
            }}
          >
            Cancelar
          </button>

          <button
            className="btn btn-danger w-50 "
            onClick={() => {
              setNoteToDelete(null);
              deleteNote(note_id, () => {
                getNotesByCustomer(customer_id);
              });
            }}
          >
            <i className="fas fa-trash me-2" />
            Eliminar
          </button>
        </div>
      </div>
    );
  };

  const handleChangeNoteInput = (event) => {
    setPropertyNote("content", event.target.value);
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  const renderContent = () => {
    if (noteToDelete === null) {
      return (
        <>
          <div className="w-100 mx-auto overflow-auto" style={{ flex: 1 }}>
            {renderNotes()}
          </div>

          <div
            className="w-100 position-relative d-flex align-items-end pt-3 pb-3 px-2 border border-top"
            style={{ minHeight: "90px" }}
          >
            <textarea
              id="note-input"
              ref={textareaRef}
              style={{
                resize: "none",
                maxHeight: "200px",
              }}
              value={note.content}
              className="form-control "
              placeholder="Escribe una nota acerca del cliente."
              onChange={handleChangeNoteInput}
            />

            <button
              type="button"
              className="btn p-2"
              style={{ width: "45px", height: "45px" }}
              onClick={handleCreateNote}
            >
              <i
                style={{ fontSize: "24px" }}
                className="fas fa-paper-plane text-primary"
              ></i>
            </button>
          </div>
        </>
      );
    } else {
      return renderDeleteAdvice();
    }
  };

  return (
    <div className="row bg-light position-relative flex-column h-100 w-100 mx-auto overflow-hidden">
      {renderContent()}
    </div>
  );
};

export default NotesOffcanvas;
