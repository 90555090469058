import React, { useContext, useEffect } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import ReservacionRow from "./ReservacionRow";
import { ReservationsContext } from "../../context/ReservationsContext";

const ReservationsTable = ({ customer_id }) => {
  const {
    customer,
    getCustomer,
  } = useContext(CustomerContext);

  const {
    currentReservationsFilter
  } = useContext(ReservationsContext);

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReservationsFilter]);
 
  const getData = () => {
    getCustomer(customer_id, { 
      reservationsStatus: currentReservationsFilter 
    });
  };
  
  const renderReservaciones = () => {
    if (customer && customer !== null) {
      if (Array.isArray(customer.class_reservations)) {
        return customer.class_reservations.map((reservation) => (
          <ReservacionRow
            key={reservation.class_reservation_id}
            reservation={reservation}
            deleteCallback={getData}
          />
        ));
      }
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>Clase</td>
            <td>Coach</td>
            <td>Fecha</td>
            <td>Reservada en</td>
            <td>Status</td>
            <td className="text-center">Acciones</td>
          </tr>
        </thead>
        <tbody>{renderReservaciones()}</tbody>
      </table>
    </div>
  );
};

export default ReservationsTable;
