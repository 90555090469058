import React, { useEffect, useContext } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import { PagesContext } from "../../context/PagesContext";
import { ModalContext } from "../../context/ModalContext";
import PageForm from "../../components/pages/PageForm";
import { Link } from "@reach/router";
import moment from "moment";
import HeaderRow from "../../components/global/HeaderRow";

const AdminPages = () => {
  const { pages, getPages, postPage } = useContext(PagesContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    modalComponent("Agregar Página", <PageForm postPage={postPage} />);
  };

  const renderPages = () => {
    if (Array.isArray(pages)) {
      if (pages.length === 0) {
        return <p className="mb-0">Aún no hay páginas.</p>;
      }
      return pages.map((page) => (
        <div
          to={`/myadmin/pages/${page.page_id}`}
          className="row border-top border-bottom py-3 hover-light text-dark no-decoration"
        >
          <div className="col-4">
            <Link to={`/myadmin/pages/${page.page_id}`}>{page.title}</Link>
          </div>
          <div className="col-4">{page.description}</div>
          <div className="col-4">
            {moment(page.updatedAt).format("DD MMM YYYY")}
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Páginas" onClick={handleCreate} />
      <HeaderRow headers={["Título", "Descripción", "Última Modificación"]} />
      <div className="card px-3 py-0 no-scale shadow-sm">{renderPages()}</div>
    </div>
  );
};

export default AdminPages;
