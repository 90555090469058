
import { getArgs } from '../utils';
import api from './api';

const route = '/notifications';

const NotificationsService = {
  getNotifications: (filters) => api.get(`${route}/admin?${getArgs(filters)}`),
  postNotification: (notification) => api.post(route, { ...notification}),
  putNotification: (notification) => api.put(route, { ...notification}),
  deleteNotification: (Notification) => api.delete(`${route}/${Notification}`),
};

export default NotificationsService;