import { ClassInstructorProvider } from "./context/SingleClassContext";
import { CoachesProvider } from "./context/CoachesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { HomeProvider } from "./context/HomeContext";
import { LocationsProvider } from "./context/LocationsContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { PaquetesProvider } from "./context/PackageContext";
import { UserProvider } from "./context/AuthContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { AnaliticasProvider } from "./context/AnaliticasContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ReservationsProvider } from "./context/ReservationsContext";
import { VideosProvider } from "./context/VideosContext";
import { VideoTypeProvider } from "./context/VideoTypeContext";
import { ProgramsProvider } from "./context/ProgramsContext";
import { UsersProvider } from "./context/UsersContext";
import { InvoicesProvider } from "./context/InvoicesContext";
import { PaymentMethodsProvider } from "./context/PaymentMethodsContext";
import { PagesProvider } from "./context/PagesContext";
import { combineComponents } from "./context";
import { PostsProvider } from "./context/PostsContext";
import { ThreadsProvider } from "./context/ThreadsContext";
import { CartsProvider } from "./context/CartsContext";
import { PropsProvider } from "./context/PropsContext";
import { MusclesProvider } from "./context/MusclesContext";
import { TestimoniosProvider } from "./context/TestimoniosContext";
import { FrequentQuestionsProvider } from "./context/FrequentQuestionsContext";
import { StreaksProvider } from "./context/StreaksContext";
import { BranchesProvider } from "./context/BranchesContext";
import { NotesProvider } from "./context/NotesContext";
import { RefundsProvider } from "./context/RefundsContext";
import { AvailableClassesProvider } from "./context/AvailableClassesContext";
import Main from "./Main";
import "./index.css";
import { AppConfigProvider } from "./context/AppConfigContext";
import { WaitlistsProvider } from "./context/WaitlistsContext";
import { NotificationsProvider } from "./context/NotificationsContext";
import { MilestonesProvider } from "./context/MilestonesContext";
import { MilestoneBenefitsProvider } from "./context/MilestoneBenefitsContext";

const AppContextProvider = combineComponents([
  AppConfigProvider,
  HomeProvider,
  NotesProvider,
  PropsProvider,
  CartsProvider,
  PostsProvider,
  PagesProvider,
  UsersProvider,
  NotesProvider,
  VideosProvider,
  StreaksProvider,
  MusclesProvider,
  ThreadsProvider,
  CoachesProvider,
  MetodosProvider,
  RefundsProvider,
  BranchesProvider,
  InvoicesProvider,
  ProgramsProvider,
  CustomerProvider,
  PurchaseProvider,
  PaquetesProvider,
  CheckoutProvider,
  BranchesProvider,
  DiscountsProvider,
  LocationsProvider,
  VideoTypeProvider,
  ClassTypeProvider,
  WaitlistsProvider,
  AnaliticasProvider,
  MilestonesProvider,
  TestimoniosProvider,
  ReservationsProvider,
  NotificationsProvider,
  ClassCategoryProvider,
  PaymentMethodsProvider,
  ClassInstructorProvider,
  AvailableClassesProvider,
  MilestoneBenefitsProvider,
  FrequentQuestionsProvider,
]);

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <AppContextProvider>
          <Main />
        </AppContextProvider>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
