import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import CustomerService from "../../services/CustomerService";
import StudioTable from "../../components/customers/StudioTable";

const AdminPresenciales = () => {
  const [query, setQuery] = useState("");
  const { customers, getStudioActiveCustomers } = useContext(CustomerContext);

  useEffect(() => {
    getStudioActiveCustomers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = () => {
    CustomerService.downloadStudioActiveCustomers().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Activas_Online.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const getCustomers = () => {
    if (query !== "") {
      let lowerQuery = String(query).toLowerCase();
      return customers.filter(
        (customer) =>
          String(customer.name).toLowerCase().startsWith(lowerQuery) ||
          String(customer.last_name).toLowerCase().startsWith(lowerQuery) ||
          String(customer.email).toLowerCase().startsWith(lowerQuery)
      );
    }
    return customers;
  };

  return (
    <div className="container-fluid">
      <div className="row mx-0 align-items-center mb-3 pb-2 border-bottom">
        <div className="col-12 col-md-4 ps-0">
          <h1>Acceso Presenciales</h1>
        </div>
        <div className="col-12 col-md-4 px-0">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-4">
          <button
            className="btn btn-sm btn-primary w-100"
            onClick={handleDownload}
          >
            <i className="fa fa-download"></i> Descargar
          </button>
        </div>
      </div>
      <div className="card no-scale p-3">
        <StudioTable customers={getCustomers()} />
      </div>
    </div>
  );
};

export default AdminPresenciales;
