import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import PaquetesReducer from "../reducers/PaquetesReducer";
import PaquetesService from "../services/PaquetesService";
import {
  ADD_CLASS_PACKAGE_TYPE,
  CREATE_PAQUETE,
  HIDE_SPINNER,
  PAQUETES_RECIBIDOS,
  REMOVE_CLASS_PACKAGE_TYPE,
  RESERVATIONS_RECIBIDAS,
  SET_PACKAGE_CLASS_TYPE_PROPERTY,
  SET_PAQUETE,
  SET_PROPIEDAD_PAQUETE,
  SHOW_SPINNER,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import AdjuntosService from "../services/AdjuntosService";

const initialState = {
  paquetes: null,
  paquete: null,
  spinner: false,
};

export const PackagesContext = createContext(initialState);

export const PaquetesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PaquetesReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const getPaquetes = () => {
    PaquetesService.getPaquetes().then((res) => {
      const { packages } = res.data;
      dispatch({ type: PAQUETES_RECIBIDOS, payload: packages });
    });
  };

  const getPaquetesEspecialesAdmin = () => {
    PaquetesService.getEventosEspecialesAdmin().then((res) => {
      const { packages } = res.data;
      dispatch({ type: PAQUETES_RECIBIDOS, payload: packages });
    });
  };

  const getAsistentesEspecial = (package_class_id) => {
    PaquetesService.getAsistentesEspecial(package_class_id).then((res) => {
      const { class_package, asistentes } = res.data;
      dispatch({ type: RESERVATIONS_RECIBIDAS, payload: asistentes });
      dispatch({ type: SET_PAQUETE, payload: class_package });
    });
  };

  const getPaquetesOnline = () => {
    PaquetesService.getOnline().then((res) => {
      const { packages } = res.data;
      dispatch({ type: PAQUETES_RECIBIDOS, payload: packages });
    });
  };

  const getAllPaquetes = () => {
    PaquetesService.getAllPaquetes().then((res) => {
      const { packages } = res.data;
      dispatch({ type: PAQUETES_RECIBIDOS, payload: packages });
    });
  };

  const getPaqueteAdmin = (package_class_id) => {
    PaquetesService.getPaqueteAdmin(package_class_id).then((res) => {
      const { class_package } = res.data;
      dispatch({ type: SET_PAQUETE, payload: class_package });
    });
  };

  const createPaquete = () => {
    dispatch({ type: CREATE_PAQUETE });
  };

  const setPropiedadPaquete = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_PAQUETE, payload: { key, value } });
  };

  const postPaquete = async (paquete) => {
    dispatch({ type: SHOW_SPINNER });

    const callback = () => {
      success("¡Paquete guardado con éxito!");
      navigate("/myadmin/paquetes");
      dispatch({ type: HIDE_SPINNER });
    };


    const handleError = (error) => {
      alert(error);
      dispatch({ type: HIDE_SPINNER });
    };

    const thumbnail_file = paquete.thumbnail_file;
    const receipt_file = paquete.receipt_file;

    if(typeof thumbnail_file?.file_id !== 'number' && thumbnail_file) {
      const formData = AdjuntosService.getFormData(thumbnail_file);
      const res = await AdjuntosService.postAdjunto(formData);

      paquete.thumbnail = res.data.file_id;
      delete paquete.thumbnail_file;
    }

    if(typeof receipt_file?.file_id !== 'number' && receipt_file) {
      const formData = AdjuntosService.getFormData(receipt_file);
      const res = await AdjuntosService.postAdjunto(formData)

      paquete.receipt = res.data.file_id;
      delete paquete.receipt_file;
    }


    if (isNaN(paquete.class_package_id)) {
      PaquetesService.postPaquete(paquete).then(callback).catch(handleError);
    } else {
      PaquetesService.putPaquete(paquete).then(callback).catch(handleError);
    }
  };
  
  const deletePaquete = (package_id) => {
    PaquetesService.deletePaquete(package_id).then(() => {
      success("¡Paquete eliminado con éxito!");
      getAllPaquetes();
      hideModal();
    });
  };

  const setPropertyClassPackageType = (object_key, key, value) => {
    dispatch({ type: SET_PACKAGE_CLASS_TYPE_PROPERTY, payload: { key, value, object_key} });
  }

  const addClassPackageType = () => {
    dispatch({
      type: ADD_CLASS_PACKAGE_TYPE,
    });
  };

  const removeClassPackageType = (object_key) => {
    dispatch({ type: REMOVE_CLASS_PACKAGE_TYPE, payload: object_key });
  }

  return (
    <PackagesContext.Provider
      value={{
        ...state,
        getPaquetes,
        postPaquete,
        deletePaquete,
        createPaquete,
        getAllPaquetes,
        getPaqueteAdmin,
        getPaquetesOnline,
        addClassPackageType,
        setPropiedadPaquete,
        getAsistentesEspecial,
        removeClassPackageType,
        getPaquetesEspecialesAdmin,
        setPropertyClassPackageType,
      }}
    >
      {children}
    </PackagesContext.Provider>
  );
};
