import React from "react";

const VideoTestimonial = ({ content, name, video_source }) => {
  const renderVideo = () => {
    if (video_source && typeof video_source === "string") {
      if (video_source.includes("youtube")) {
        const source =
          "https://www.youtube.com/embed/" + video_source.split("v=")[1];
        return (
          <iframe
            src={source}
            title="YouTube video player"
            className="mw-100 w-100 shadow"
            height="350px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        );
      }
      if (video_source.includes("drive")) {
        return (
          <iframe
            title={name}
            src={video_source}
            className="mw-100 w-100"
            height="400px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        );
      }
      return (
        <video className="mw-100 w-100" controls>
          <source src={video_source} type="video/mp4" />
        </video>
      );
    }
  };

  return (
    <div className="container-fluid px-0 text-center">
      {content && (
        <h3 className="testimonial-text px-3">
          "{content}" - <b>{name}</b>
        </h3>
      )}
      {renderVideo()}
    </div>
  );
};

export default VideoTestimonial;
