import React, { useContext, useEffect, useState } from "react";
import { UsersContext } from "../../context/UsersContext";
import CoachCustomerOption from "./CoachCustomerOption";
import CoachCustomer from "./CoachCustomer";

const SelectCoachCustomer = ({ coach, modifier }) => {
  const [customer, setCustomer] = useState(null);
  const [query, setQuery] = useState("");

  const { users, getAllbranchScopes } = useContext(UsersContext);

  useEffect(() => {
    if (!Array.isArray(users)) {
      if (query !== "") {
        getAllbranchScopes({ query, branch_id: coach.branch_id });
      }
    } else {
      getAllbranchScopes({ query, branch_id: coach.branch_id });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleCoachCustomer = (customer) => {
    modifier("user_id", customer.user_id);
    setCustomer(customer);
  };

  const clearCoachCustomer = () => {
    modifier("user_id", null);
    setCustomer(null);
  };

  const renderCustomers = () => {
    if (coach.user_id !== null) {
      if (customer !== null) {
        return (
          <CoachCustomer user={{ customer }} modifier={clearCoachCustomer} />
        );
      }
      if (coach.user !== null) {
        let { user } = coach;
        return <CoachCustomer user={user} modifier={clearCoachCustomer} />;
      }
    }
    if (users && users !== null) {
      return users.map(({ customer, user_id }) => (
        <CoachCustomerOption
          key={customer.customer_id}
          modifier={handleCoachCustomer}
          customer={{ ...customer, user_id }}
        />
      ));
    }
  };

  return (
    <div>
      <label>Asignar Usuario a Coach</label>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Buscar..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {renderCustomers()}
    </div>
  );
};

export default SelectCoachCustomer;
