import React, { useContext, useEffect } from "react";
import { VideosContext } from "../../context/VideosContext";
import { MusclesContext } from "../../context/MusclesContext";
import VideoMuscleFormRow from "./VideoMuscleFormRow";

const VideoMuscleSelect = () => {
  const { video, setPropiedadVideo } = useContext(VideosContext);
  const { muscles, getMuscles } = useContext(MusclesContext);

  useEffect(() => {
    getMuscles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeVideoMuscle = (muscle_id, key, value) => {
    const video_muscles = [...video.video_muscles];
    let index = video_muscles.findIndex(
      (muscle) => muscle.muscle_id === muscle_id
    );
    if (index === -1) {
      const current_muscle = muscles.find(
        (muscle) => muscle.muscle_id === muscle_id
      );
      video_muscles.push(current_muscle);
      index = video_muscles.findIndex(
        (muscle) => muscle.muscle_id === muscle_id
      );
    }
    // Add or remove muscle
    if (key === "checked") {
      if (!value && index !== -1) video_muscles.splice(index, 1);
      // Modify value of an existing muscle
    } else {
      const current_video_muscle = video_muscles[index];
      current_video_muscle[key] = value;
    }
    setPropiedadVideo("video_muscles", video_muscles);
  };

  const renderMuscles = () => {
    if (Array.isArray(muscles) && video !== null) {
      return muscles.map((muscle) => (
        <VideoMuscleFormRow
          muscle={muscle}
          video={video}
          key={muscle.muscle_id}
          modifier={handleChangeVideoMuscle}
        />
      ));
    }
  };
  return (
    <div className="mb-3">
      <h3>Músculos</h3>
      <div className="row bg-light py-2 align-items-center border small bold">
        <div className="col-1"></div>
        <div className="col-6">Nombre</div>
        <div className="col-5">Impacto</div>
      </div>
      {renderMuscles()}
    </div>
  );
};

export default VideoMuscleSelect;
