import React, { createContext, useReducer, useContext } from "react";
import MilestonesService from "../services/MilestonesService";
import MilestonesReducer from "../reducers/MilestonesReducer";
import {
  MILESTONES_RECEIVED,
  SET_MILESTONE,
  CREATE_MILESTONE,
  SET_PROPERTY_MILESTONE,
} from "../types/milestones";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  milestones: null,
  milestone: null,
};

export const MilestonesContext = createContext(initialState);

export const MilestonesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MilestonesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getMilestones = () => {
    MilestonesService.getMilestones()
      .then((response) => {
        const { milestones } = response.data;
        dispatch({ type: MILESTONES_RECEIVED, payload: milestones });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleMilestone = (milestone_id) => {
    MilestonesService.getSingleMilestone(milestone_id)
      .then((response) => {
        const { milestone } = response.data;
        dispatch({ type: SET_MILESTONE, payload: milestone });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setMilestone = (milestone) => {
    dispatch({ type: SET_MILESTONE, payload: milestone });
  };

  const createMilestone = () => {
    dispatch({ type: CREATE_MILESTONE });
  };

  const setPropertyMilestone = (key, value) => {
    dispatch({ type: SET_PROPERTY_MILESTONE, payload: { key, value } });
  };

  const saveMilestone = (milestone, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = MilestonesService.putMilestone;
    if (isNaN(parseInt(milestone.milestone_id))) {
      service = MilestonesService.postMilestone;
    }
    service(milestone)
      .then(() => {
        success("Milestone saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteMilestone = (milestone_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    MilestonesService.deleteMilestone(milestone_id)
      .then(() => {
        success("Milestone deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <MilestonesContext.Provider
      value={{
        ...state,
        setMilestone,
        getMilestones,
        saveMilestone,
        deleteMilestone,
        createMilestone,
        getSingleMilestone,
        setPropertyMilestone,
      }}
    >
      {children}
    </MilestonesContext.Provider>
  );
};
