import React, { useEffect } from "react";
import ReactSelect from "react-select";

const DynamicSearchSelect = ({
  value,
  items,
  modifier,
  getItems,
  valueKey,
  labelKey,
}) => {

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (query) => {
    getItems({ query });
  };

  const getOptions = () => {
    if(!Array.isArray(items)) return [];
    return items.map((item) => ({
      value: item[valueKey],
      label: item[labelKey],
    }));
  };

  const getValue = (options) => {
    if (value && value !== "" && value !== null) {
      return options.find((option) => option.value === value);
    }
  };

  const renderSelect = () => {
    const options = getOptions();
    const value = getValue(options);
    return (
      <ReactSelect
        isSearchable
        value={value}
        className="px-0"
        options={options}
        onInputChange={fetchData}
        onChange={({ value}) => modifier(value)}
      />
    );
  };

  return <div>{renderSelect()}</div>;
};

export default DynamicSearchSelect;
