import React from "react";

const ClassTypeRow = ({ category, editClassType, confirmDelete, userRole }) => {

  const renderSuperAdminCols = () => {
    if(userRole === 'super_admin') {
      return(
        <td>
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => editClassType(category.class_type_id)}
          >
            <i className="fa fa-edit me-1"></i> Editar
          </button>
          <button
            className="btn btn-outline-danger btn-sm mx-3"
            onClick={() => confirmDelete(category)}
          >
            <i className="fa fa-trash me-1"></i> Eliminar
          </button>
        </td>
      )
    }
  }

  return (
    <tr className="small bg-white border-bottom align-middle hover-light">
      <td>{category.name}</td>
      <td>
        <i className={category.icon}></i>
      </td>
      <td>
        <div
          className="color-legend"
          style={{ backgroundColor: category.color }}
        ></div>
      </td>
      <td>{category.mapa}</td>
      {renderSuperAdminCols()}
    </tr>
  );
};

export default ClassTypeRow;
