import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import MuscleTable from "../../components/muscles/MuscleTable";
import { MusclesContext } from "../../context/MusclesContext";
import { ModalContext } from "../../context/ModalContext";
import MuscleForm from "../../components/muscles/MuscleForm";
import ConfirmDeleteMuscle from "../../components/muscles/ConfirmDeleteMuscle";
import useUserRoles from "../../hooks/auth/useUserRoles";

const AdminMuscles = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { muscles, setMuscle, getMuscles, createMuscle } =
    useContext(MusclesContext);

  const { isSuperAdmin } = useUserRoles();

  useEffect(() => {
    getMuscles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateMuscle = () => {
    createMuscle();
    modalComponent(
      "Agregar Músculo",
      <MuscleForm handleCallback={getMuscles} />
    );
  };

  const handleEditMuscle = (muscle) => {
    setMuscle(muscle);
    modalComponent(
      "Editar Músculo",
      <MuscleForm handleCallback={getMuscles} />
    );
  };

  const handleDelete = (muscle) => {
    setMuscle(muscle);
    modalComponent(
      "Eliminar Músculo",
      <ConfirmDeleteMuscle muscle={muscle} handleCancel={clearModal} />
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title="Muscles"
        hideButton={!isSuperAdmin()}
        onClick={handleCreateMuscle}
      />
      <div className="card px-0 pt-0 pb-3 shadow">
        <MuscleTable
          muscles={muscles}
          handleEdit={handleEditMuscle}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
};

export default AdminMuscles;
