import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { PackagesContext } from "../../context/PackageContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import RevokeForm from "../../components/purchases/RevokeForm";
import { hideModal } from "../../utils";
import CancelForm from "../../components/purchases/CancelForm";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import InvoicesTable from "../../components/invoices/InvoicesTable";
import PanelTitle from "../../components/global/PanelTitle";
import AddPurchaseForm from "../../components/purchases/AddPurchaseForm";
import { AuthContext } from "../../context/AuthContext";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import CustomerData from "../../components/customers/CustomerData";
import CustomerActions from "../../components/customers/CustomerActions";
import StreaksTable from "../../components/streaks/StreaksTable";
import NotesOffcanvas from "../../components/notes/NotesOffcanvas";
import { NotesContext } from "../../context/NotesContext";
import { navigate } from "@reach/router";
import AdminAvailableClasses from "./AdminAvailableClasses";
import OnlineAccessTable from "../../components/customers/OnlineAccessTable";
import ReservationsTable from "../../components/reservaciones/ReservationsTable";
import ReservationTableFilters from "../../components/reservaciones/ReservationTableFilters";

const AdminSingleUsuario = ({ customer_id }) => {
  const [currentTab, setCurrentTab] = useState("Compras");

  const {
    customer,
    getCustomer,
    clearCustomer,
    extenderAcceso,
    deleteCustomer,
  } = useContext(CustomerContext);
  const { paquetes, getPaquetes } = useContext(PackagesContext);

  const { user } = useContext(AuthContext);

  const { modalComponent, offcanvasComponent } = useContext(ModalContext);
  const { getNotesByCustomer } = useContext(NotesContext);

  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  const { cancelPurchase, revokePurchase, editExpiration } =
    useContext(PurchasesContext);

  useEffect(() => {
    getViewData();
    return clearCustomer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const getViewData = () => {
    getPaymentMethods();
    getCustomer(customer_id);
    getPaquetes();
  };

  const toggleModal = () => {
    modalComponent(
      "Agregar Acceso",
      <AddPurchaseForm
        customer={customer}
        extenderAcceso={extenderAcceso}
        paymentMethods={payment_methods}
        paquetes={paquetes}
      />
    );
  };

  const confirmDelete = () => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el cliente {customer.name}{" "}
          {customer.last_name} con el correo {customer.email}?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteCustomer(customer_id)}
            >
              <i className="fa fa-trash"></i> Eliminar Cliente
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const confirmRevoke = (purchase) => {
    modalComponent(
      "Precaución",
      <RevokeForm
        purchase={purchase}
        customer_id={customer_id}
        revokePurchase={revokePurchase}
      />
    );
  };

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm
        purchase={purchase}
        customer_id={customer_id}
        cancelPurchase={cancelPurchase}
      />
    );
  };

  const renderPanel = () => {
    if (customer && customer !== null) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <CustomerData
              customer={customer}
              handleEdit={() =>
                navigate(`/myadmin/customer/${customer_id}/edit`)
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomerActions
              handleNotesOffcanvas={handleNotesOffcanvas}
              customer={customer}
            />
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const handleNotesOffcanvas = () => {
    offcanvasComponent(
      "Notas",
      <NotesOffcanvas
        customer_id={customer_id}
        handleCallback={() => getNotesByCustomer(customer_id)}
      />
    );
  };

  const renderNotesBtn = () => {
    return (
      <li
        className={`nav-item customer-nav-item hide-mobile`}
        onClick={() => {
          handleNotesOffcanvas();
        }}
      >
        <p className={`nav-link mb-0 text-dark `}>Notas</p>
      </li>
    );
  };

  const renderTabs = () => {
    const tabs = [
      "Compras",
      "Cargos",
      "Streaks",
      "Reservaciones",
      "Clases",
      "Accesos",
    ];

    return tabs.map((tab) => {
      const active = tab === currentTab ? true : false;

      return (
        <li
          key={tab}
          className={`nav-item customer-nav-item`}
          onClick={() => setCurrentTab(tab)}
        >
          <p
            className={`nav-link mb-0 ${
              active ? "bg-primary text-white" : "text-dark"
            } `}
          >
            {tab}
          </p>
        </li>
      );
    });
  };

  const renderPurchases = () => {
    if (currentTab === "Compras" && customer && customer !== null) {
      let orderedPurchases = customer?.purchases?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      });

      if (orderedPurchases?.length <= 0) orderedPurchases = [];

      return (
        <div className="card p-3 pb-1 no-scale my-3">
          <PanelTitle title="Compras" onClick={toggleModal} />
          <PurchasesTable
            user={user}
            hideColumns={["type", "customer"]}
            editExpiration={editExpiration}
            purchases={orderedPurchases ? orderedPurchases : []}
            confirmRevoke={confirmRevoke}
            confirmCancel={confirmCancel}
          />
        </div>
      );
    }
  };

  const renderInvoices = () => {
    if (currentTab === "Cargos") {
      return (
        <div className="card p-3 pb-1 no-scale my-3">
          <PanelTitle title="Cargos" hideButton />
          <InvoicesTable
            user={user}
            customer_id={customer_id}
            invoices={customer !== null ? customer.invoices : []}
          />
        </div>
      );
    }
  };

  const renderAvailableClasses = () => {
    if (currentTab === "Clases") {
      return <AdminAvailableClasses customer_id={customer_id} />;
    }
  };

  const renderStreaks = () => {
    if (currentTab === "Streaks") {
      return <StreaksTable customer_id={customer_id} />;
    }
  };

  const renderReservations = () => {
    if (currentTab === "Reservaciones") {
      return (
        <div className="card no-scale my-3">
          <PanelTitle title="Reservaciones" hideButton />
          <ReservationTableFilters/>
          <ReservationsTable customer_id={customer_id}/>
        </div>
      );
    }
  };

  const renderAccesosOnlineTable = () => {
    if (currentTab === "Accesos") {
      return <OnlineAccessTable accesses={customer?.online_accesses} />;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row pb-2 border-bottom mx-0 mb-3 align-items-center">
        <div className="col col-md-6 ps-0">
          <h1>Cliente</h1>
        </div>
        <div className="col col-md-6 pe-0 text-end"></div>
      </div>
      <div className="card no-scale mb-3">{renderPanel()}</div>

      <div className="card p-3 my-3">
        <div className="row mx-auto w-100">
          <div className="col-12 px-0">
            <ul className="nav nav-pills customer-nav">
              {renderTabs()}
              {renderNotesBtn()}
            </ul>
          </div>
        </div>
      </div>

      {renderPurchases()}
      {renderInvoices()}
      {renderStreaks()}
      {renderReservations()}
      {renderAvailableClasses()}
      {renderAccesosOnlineTable()}

      <button className="btn btn-outline-danger mt-5" onClick={confirmDelete}>
        <i className="fa fa-trash"></i> Eliminar Cliente
      </button>
    </div>
  );
};

export default AdminSingleUsuario;
