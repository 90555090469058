import { useContext, useEffect, useState } from "react";
import CustomFieldsService from "../../services/CustomFieldsService";
import { ModalContext } from "../../context/ModalContext";
import DynamicSearchSelect from "./DynamicSearchSelect";
import FileInput from "../common/FileInput";
import { getValue } from "../../utils";
import Input from "../common/Input";

const DynamicForm = ({
  object,
  modifier,
  objectType,
  saveAction,
  classNames,
  handleCancel,
  exclude = [],
  allSteps = [],
  handleCallback,
  selectFields = [],
  excludeTypes = [],
}) => {
  const [fields, setFields] = useState([]);
  // const [progress, setProgress] = useState(0);
  const [steps, setSteps] = useState(allSteps);
  const [excludeFields, setExcludeFields] = useState([]);

  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    handleExclude();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclude])

  const getCustomFields = () => {
    CustomFieldsService.getCustomFields({ object: objectType }).then((res) => {
      const { custom_fields } = res.data;
      setFields(custom_fields);
    });
  };

  const handleExclude = () => {
    let current = [...exclude];
    if (
      !current.includes("user_id") &&
      selectFields.findIndex(({ type }) => type === "user") === -1
    ) {
      current.push("user_id");
    }
    setExcludeFields(current);
  };

  const handleStep = (id) => {
    // const newProgress = steps.find((step) => step.id === id).progress;

    const newSteps = steps.map((step) => {
      if (step.id === id) {
        step.showComponent = true;
      } else {
        step.showComponent = false;
      }

      if (step.id <= id) {
        step.isActive = true;
      } else {
        step.isActive = false;
      }
      return step;
    });
    setSteps(newSteps);
    // setProgress(newProgress);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (typeof saveAction === "function") {
      saveAction(object, handleCallback);
    }
  };
  const getQuestionClassName = (question) => {
    if (
      [
        "number",
        "zip_code",
        "latitude",
        "longitude",
        "interior_number",
        "city",
        "state",
        "country",
      ].includes(question.name)
    ) {
      return "col-6";
    }
    if (Array.isArray(classNames)) {
      const hasClassName = classNames.find(
        (field) => field.key === question.key
      );
      if (hasClassName) return hasClassName.className;
    }
    return question.className
      ? question.className
      : question.type === "boolean"
      ? "col-6"
      : "";
  };

  const renderForm = () => {
    if (Array.isArray(fields) && object !== null) {
      let questionsRender = [...fields];
      questionsRender = questionsRender
        .filter((question) => {
          if (question.precondition) {
            if (!question.precondition(object)) {
              return null;
            }
          }
          return question;
        })
        .filter((question) => question !== null)
        .filter(
          (question) =>
            !excludeFields.includes(question.key) &&
            !excludeFields.includes(question.name)
        );
      return questionsRender.map((question) => {
        const selectIndex = selectFields.findIndex(
          (item) =>
            item.type === question.type ||
            item.key === question.key ||
            item.key === question.id ||
            item.name === question.name
        );
        const isSelectable = selectIndex !== -1;
        const isNormalInput = [
          "text",
          "number",
          "date",
          "datetime",
          "boolean",
          "file",
        ].includes(question.type);
        if (
          !isNormalInput &&
          !isSelectable &&
          question.type !== objectType &&
          !excludeTypes.includes(question.type)
        ) {
          return (
            <div
              key={question.id || question.name || question.key}
              className="mb-3 px-0"
            >
              <p className="bold mx-2 px-1 mb-0 text-capitalize">
                {question.type}
              </p>
              <DynamicForm
                exclude={fields.map(({ key }) => key)}
                objectType={question.type}
                classNames={classNames}
                excludeTypes={["user"]}
                modifier={modifier}
                object={object}
              />
            </div>
          );
        }
        if (isSelectable) {
          const selectItem = { ...selectFields[selectIndex] };
          delete selectItem.key;
          return (
            <div
              key={question.id || question.name || question.key}
              className="mb-3"
            >
              <p className="bold mb-0 text-capitalize">
                {isNormalInput ? question.id : question.type}
              </p>
              <DynamicSearchSelect
                {...selectItem}
                value={getValue(object, question.id)}
                modifier={(value) => modifier(question.key, value)}
              />
            </div>
          );
        }
        if (question.type === "file") {
          return (
            <div
              key={question.id || question.name || question.key}
              className="container-fluid my-3"
            >
              <FileInput modifier={(value) => modifier(question.key, value)} />
            </div>
          );
        }
        return (
          <Input
            {...question}
            type={question.type === "datetime" ? "date" : question.type}
            key={question.id || question.name || question.key}
            modifier={modifier}
            value={getValue(object, question.id)}
            className={getQuestionClassName(question)}
            label={question.label ? question.label : question.name}
          />
        );
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">{renderForm()}</div>
      {typeof saveAction === "function" && (
        <div className="row mt-3">
          <div className="col">
            <button
              type="button"
              onClick={handleCancel ? handleCancel : clearModal}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col">
            <button type="submit" className="btn w-100 btn-primary">
              Guardar
            </button>
          </div>
        </div>
      )}
      {steps
        .filter((step) => step.showComponent && step.button.text !== "")
        .map((step, index) => (
          <button
            key={index}
            className="btn btn-primary w-100 mt-4"
            type={step.button.type}
            onClick={() => {
              if (step.id < steps.length) {
                handleStep(step.id + 1);
              }
            }}
          >
            {step.button.text}
          </button>
        ))}
    </form>
  );
};

export default DynamicForm;
