import React from "react";
import moment from "moment";
import { S3_ENDPOINT } from "../../utils";

const CustomerData = ({ customer, handleEdit }) => {
  const { name, last_name, birthdate, instagram, phone, email } = customer;

  const getSrc = () => {
    if (customer.file && customer.file !== null) {
      return `${S3_ENDPOINT}/files/${customer.file.name}.${customer.file.type}`;
    }
    return "http://localhost:3000/images/logo_negro.png";
  };

  const renderBirthdate = () => {
    if (moment(birthdate).isValid()) {
      return moment(birthdate).utc().format("DD MMM YYYY");
    }
    return "N/D";
  };

  return (
    <div className="container-fluid px-0 customer-data__container">
      <div className="row mb-3 align-items-center mx-0">
        <div className="col-4 col-md-2 ps-0">
          <img
            src={getSrc()}
            className="w-100 border p-3 br-10 profile-image"
            alt="Perfil de cliente"
          />
        </div>
        <div className="col-8 col-md-10">
          <h4 className="mb-0">
            {name} {last_name}
          </h4>
        </div>
      </div>
      <div className="mb-1">
        <i className="fa fa-envelope me-3"></i>
        {email}
      </div>
      <div className="mb-1">
        <i className="fa fa-birthday-cake me-3"></i>
        {renderBirthdate()}
      </div>
      <div className="mb-1">
        <i className="fa fa-phone me-3"></i>
        {phone}
      </div>
      <div className="mb-1">
        <i className="fab fa-instagram me-3"></i>
        {instagram !== null ? instagram : "N/D"}
      </div>
      <button onClick={handleEdit} className="btn btn-outline-dark mt-3">
        <i className="fa fa-edit me-3"></i>Editar Información
      </button>
    </div>
  );
};

export default CustomerData;
