import React, { createContext, useContext, useReducer } from "react";
import PurchasesService from "../services/PurchasesService";
import OrdenesReducer from "../reducers/OrdenesReducer";
import {
  CLEAR_ORDENES,
  HIDE_SPINNER,
  ORDENES_RECIBIDAS,
  SET_ORDEN,
  SET_PROPERTY_PURCHASE,
  SHOW_SPINNER,
} from "../types";
import { ModalContext } from "./ModalContext";
import { CustomerContext } from "./CustomerContext";
import { hideModal } from "../utils";

const initialState = {
  purchases: null,
  purchase: null,
  spinner: false,
};

export const PurchasesContext = createContext(initialState);

export const PurchaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OrdenesReducer, initialState);

  const { getCustomer } = useContext(CustomerContext);

  const { success, alert, clearModal } = useContext(ModalContext);

  const getPurchases = (start_date, end_date, filters) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: CLEAR_ORDENES });
    PurchasesService.getPurchases(start_date, end_date, filters)
      .then((res) => {
        const { purchases } = res.data;

        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: ORDENES_RECIBIDAS, payload: purchases });
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const getPurchase = (purchase_id) => {
    PurchasesService.getPurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_ORDEN, payload: purchase });
    });
  };

  const setPurchase = (purchase) => {
    dispatch({ type: SET_ORDEN, payload: purchase });
  };

  const cancelPurchase = (purchase_id, reason, customer_id) => {
    PurchasesService.cancelPurchase(purchase_id, reason)
      .then(() => {
        success("Compra cancelada con éxito.");
        getCustomer(customer_id);
        hideModal();
      })
      .catch(alert);
  };

  const revokePurchase = (purchase_id, customer_id) => {
    dispatch({ type: SHOW_SPINNER });
    PurchasesService.revokePurchase(purchase_id)
      .then(() => {
        success("Compra revocada con éxito.");
        dispatch({ type: HIDE_SPINNER });
        getCustomer(customer_id);
        hideModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const downloadPurchases = (start_date, end_date, filters) => {
    PurchasesService.downloadPurchases(start_date, end_date, filters)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Purchases_${start_date}_${end_date}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const updatePurchase = (purchase) => {
    dispatch({ type: SHOW_SPINNER });
    PurchasesService.updatePurchase(purchase)
      .then(() => {
        success("Compra actualizada con éxito");
        getCustomer(purchase.customer_id);
        dispatch({ type: HIDE_SPINNER });
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const setPropertyPurchase = (key, value) => {
    dispatch({ type: SET_PROPERTY_PURCHASE, payload: { key, value } });
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        setPurchase,
        getPurchase,
        getPurchases,
        revokePurchase,
        cancelPurchase,
        downloadPurchases,
        updatePurchase,
        setPropertyPurchase,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
