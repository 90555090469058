import React from "react";
import { S3_ENDPOINT, getValue } from "../../utils";
import moment from "moment";

const CoachRow = ({ coach, editCoach, deleteCoach }) => {
  const { name, last_name } = coach;

  const renderBirthdate = () => {
    if (coach.birthdate) {
      const birthdate = moment(coach.birthdate).format("DD MMM YYYY");
      return birthdate;
    }
  };

  const renderImage = () => {
    if (coach.file?.name) {
      return (
        <img
          src={`${S3_ENDPOINT}/${coach.file?.name}.${coach.file?.type}`}
          className="me-1"
          width={40}
          height={40}
          alt="coach"
        />
      );
    }
  };

  return (
    <tr>
      <td>
        <p className="bold mb-0" style={{ fontSize: "14px" }}>
          {renderImage()}
          {name} {last_name !== null ? last_name : ""}
        </p>
      </td>
      <td>
        <a
          href={`https://instagram.com/${getValue(coach, "instagram")}`}
          className="text-primary"
          rel="noreferrer"
          target="_blank"
        >
          <i className="fab fa-instagram me-2" />
          {getValue(coach, "instagram")}
        </a>
      </td>
      <td>{renderBirthdate()}</td>
      <td>
        {getValue(coach, "show_home", "boolean") && (
          <i className="fa fa-check-circle text-primary" />
        )}
      </td>
      <td>
        {getValue(coach, "order")}
      </td>
      <td>
        <i className="fa fa-star text-warning show-mobile" />{" "}
        {getValue(coach, "video_stars_average")}
      </td>
      <td>
        <i className="fa fa-certificate text-warning show-mobile" />{" "}
        {getValue(coach, "class_stars_average")}
      </td>
      <td>
        <button
          className="btn btn-outline-dark me-2 btn-sm"
          onClick={() => editCoach(coach)}
        >
          <i className="fa fa-edit"></i>
          <span className="show-mobile ms-2">Editar</span>
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => deleteCoach(coach)}
        >
          <i className="fa fa-trash"></i>
          <span className="show-mobile ms-2">Eliminar</span>
        </button>
      </td>
    </tr>
  );
};

export default CoachRow;
