import api from './api';

const route = '/streaks';

const StreakService = {
  getCustomerStreaks: (customer_id) => api.get(`${route}/customer/${customer_id}`),
  putStreak: (streak) => api.put(route, { ...streak}),
  deleteStreak: (Streak) => api.delete(`${route}/${Streak}`),
};

export default StreakService;