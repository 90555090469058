import React, { createContext, useReducer, useContext } from "react";
import MilestoneBenefitsService from "../services/MilestoneBenefitsService";
import MilestoneBenefitsReducer from "../reducers/MilestoneBenefitsReducer";
import {
  MILESTONEBENEFITS_RECEIVED,
  SET_MILESTONEBENEFIT,
  CREATE_MILESTONEBENEFIT,
  SET_PROPERTY_MILESTONEBENEFIT,
} from "../types/milestone_benefits";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import AdjuntosService from "../services/AdjuntosService";

const initialState = {
  milestone_benefits: null,
  milestone_benefit: null,
};

export const MilestoneBenefitsContext = createContext(initialState);

export const MilestoneBenefitsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MilestoneBenefitsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getMilestoneBenefits = (filters) => {
    MilestoneBenefitsService.getMilestoneBenefits(filters)
      .then((response) => {
        const { milestone_benefits } = response.data;
        dispatch({
          type: MILESTONEBENEFITS_RECEIVED,
          payload: milestone_benefits,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleMilestoneBenefit = (milestone_benefit_id) => {
    MilestoneBenefitsService.getSingleMilestoneBenefit(milestone_benefit_id)
      .then((response) => {
        const { milestone_benefit } = response.data;
        dispatch({ type: SET_MILESTONEBENEFIT, payload: milestone_benefit });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setMilestoneBenefit = (milestone_benefit) => {
    dispatch({ type: SET_MILESTONEBENEFIT, payload: milestone_benefit });
  };

  const createMilestoneBenefit = () => {
    dispatch({ type: CREATE_MILESTONEBENEFIT });
  };

  const setPropertyMilestoneBenefit = (key, value) => {
    dispatch({ type: SET_PROPERTY_MILESTONEBENEFIT, payload: { key, value } });
  };

  const saveMilestoneBenefit = (milestone_benefit, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = MilestoneBenefitsService.putMilestoneBenefit;
    if (isNaN(parseInt(milestone_benefit.milestone_benefit_id))) {
      service = MilestoneBenefitsService.postMilestoneBenefit;
    }
    const promises = [];
    if (typeof milestone_benefit.file_id === "object") {
      promises.push(
        new Promise((resolve, reject) => {
          const formData = AdjuntosService.getFormData(
            milestone_benefit.file_id
          );
          AdjuntosService.postAdjunto(formData)
            .then((res) => {
              const { file_id } = res.data;
              milestone_benefit.file_id = file_id;
              resolve();
            })
            .catch(reject);
        })
      );
    }
    Promise.all(promises).then(() => {
      service(milestone_benefit)
        .then(() => {
          success("MilestoneBenefit saved.");
          dispatch({ type: HIDE_SPINNER });
          clearModal();
          if (typeof callback === "function") {
            callback();
          }
        })
        .catch((error) => {
          dispatch({ type: HIDE_SPINNER });
          alert(error);
        });
    });
  };

  const deleteMilestoneBenefit = (milestone_benefit_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    MilestoneBenefitsService.deleteMilestoneBenefit(milestone_benefit_id)
      .then(() => {
        success("MilestoneBenefit deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <MilestoneBenefitsContext.Provider
      value={{
        ...state,
        setMilestoneBenefit,
        getMilestoneBenefits,
        saveMilestoneBenefit,
        deleteMilestoneBenefit,
        createMilestoneBenefit,
        getSingleMilestoneBenefit,
        setPropertyMilestoneBenefit,
      }}
    >
      {children}
    </MilestoneBenefitsContext.Provider>
  );
};
