import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import AdminTable from "../../components/common/AdminTable";
import { MilestonesContext } from "../../context/MilestonesContext";
import { MilestoneBenefitsContext } from "../../context/MilestoneBenefitsContext";
import MilestoneBenefitForm from "../../components/milestones/MilestoneBenefitForm";
import { ModalContext } from "../../context/ModalContext";

const AdminSingleMilestone = ({ milestone_id }) => {
  const { milestone, getSingleMilestone } = useContext(MilestonesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { milestone_benefits, getMilestoneBenefits } = useContext(
    MilestoneBenefitsContext
  );

  useEffect(() => {
    getSingleMilestone(milestone_id);
    getMilestoneBenefits({ milestone_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone_id]);

  const handleCreateEdit = (milestone_benefit) => {
    const milestone_beneift_id = milestone?.milestone_beneift_id
      ? milestone_benefit.milestone_beneift_id
      : null;
    modalComponent(
      `${milestone_beneift_id !== null ? "Editar" : "Agregar"} Beneficio`,
      <MilestoneBenefitForm
        milestone_beneift_id={milestone_beneift_id}
        handleCancel={clearModal}
      />
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title={milestone && milestone !== null ? milestone.name : "Nivel"}
        onClick={handleCreateEdit}
      />
      <div className="card shadow p-3">
        <AdminTable
          editRow={handleCreateEdit}
          rows={milestone_benefits}
          headers={["Nombre", "Tipo", "Valor"]}
          exclude={[
            "milestone_benefit_id",
            "milestone_id",
            "createdAt",
            "updatedAt",
            "deletedAt",
            "file_id",
            "file",
          ]}
        />
      </div>
    </div>
  );
};

export default AdminSingleMilestone;
