import React, { useState } from "react";

const SelectPrograms = ({ selected, programs, addProgram, removeProgram }) => {
  const [query, setQuery] = useState("");

  const renderPrograms = () => {
    if (Array.isArray(programs) && query !== "") {
      let programsRender = [...programs];
      if (query !== "") {
        programsRender = programsRender.filter((program) =>
          String(program.name)
            .toLowerCase()
            .startsWith(String(query).toLowerCase())
        );
      }
      if (Array.isArray(selected)) {
        const current = selected.map((program) => program.program_id);
        programsRender = programsRender.filter(
          (program) => !current.includes(program.program_id)
        );
      }
      return programsRender.slice(0, 3).map((program) => (
        <li
          key={program.program_id}
          className="dropdown-item border"
          onClick={() => addProgram(program)}
        >
          {program.name}
        </li>
      ));
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      return selected.map((program) => (
        <span
          key={program.program_id}
          className="badge badge-pill bg-light border text-dark p-2 me-1"
        >
          {program.name} {program.last_name}{" "}
          <i
            className="fa fa-times small ms-1 pointer"
            onClick={() => removeProgram(program.program_id)}
          ></i>
        </span>
      ));
    }
  };

  return (
    <div className="mb-3">
      <label>Programa(s)</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Buscar por nombre"
        onChange={(e) => setQuery(e.target.value)}
      />
      <div>{renderPrograms()}</div>
      <div className="py-2">{renderSelected()}</div>
    </div>
  );
};

export default SelectPrograms;
