import React, { useContext } from "react";
import ConfirmDelete from "../global/ConfirmDelete";
import { ModalContext } from "../../context/ModalContext";
import SingleClassForm from "./SingleClassForm";
import { SingleClassContext } from "../../context/SingleClassContext";

const SingleClassActions = ({
  notifying,
  hideLabels,
  single_class,
  setNotifying,
  handleCallback,
}) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { eliminarClase } = useContext(SingleClassContext);

  const handleEdit = () => {
    if(notifying) {
      setNotifying(false);
    }
    modalComponent(
      "Editar Clase",
      <SingleClassForm
        handleCancel={clearModal}
        handleCallback={handleCallback}
        single_class_id={single_class?.single_class_id}
      />
    );
  };

  const confirmDeleteClass = () => {
    if(notifying) {
      setNotifying(false);
    }
    modalComponent(
      "Eliminar Clase",
      <ConfirmDelete
        message={`¿Estás segura que deseas eliminar la clase ${single_class?.class_type?.name}?`}
        handleDelete={() => eliminarClase(single_class?.single_class_id)}
      />
    );
  };

  return (
    <div className="row">
      <div className="container-fluid px-0">
        <button
          className="btn btn-sm btn-outline-dark mx-2"
          onClick={handleEdit}
        >
          <i className="fa fa-edit"></i>{" "}
          {!hideLabels && <span className="ms-1">Editar</span>}
        </button>
        <button
          className="btn btn-sm btn-outline-danger mx-2"
          onClick={confirmDeleteClass}
        >
          <i className="fa fa-trash"></i>{" "}
          {!hideLabels && <span className="ms-1">Eliminar</span>}
        </button>
      </div>
    </div>
  );
};

export default SingleClassActions;
