
import api from './api';

const route = '/milestones';

const MilestonesService = {
  getMilestones: () => api.get(route),
  getSingleMilestone: (milestone_id) => api.get(`${route}/${milestone_id}`),
  postMilestone: (milestone) => api.post(route, { ...milestone}),
  putMilestone: (milestone) => api.put(route, { ...milestone}),
  deleteMilestone: (Milestone) => api.delete(`${route}/${Milestone}`),
};

export default MilestonesService;