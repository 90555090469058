import React from "react";
import PostCard from "./PostCard";

const PostList = ({
  posts,
  hideVideo,
  setReplyTo,
  setCustomer,
  handleVideo,
  hideReplies,
  handleReport,
  handleThread,
  handleCallback,
}) => {
  const renderPosts = () => {
    if (Array.isArray(posts)) {
      if (posts.length === 0) return <p>No hay comentarios.</p>;
      return posts.map((post) => (
        <PostCard
          key={post.post_id}
          post={post}
          hideVideo={hideVideo}
          setReplyTo={setReplyTo}
          setCustomer={setCustomer}
          handleVideo={handleVideo}
          hideReplies={hideReplies}
          handleReport={handleReport}
          handleThread={handleThread}
          handleCallback={handleCallback}
        />
      ));
    }
  };
  return <div>{renderPosts()}</div>;
};

export default PostList;
