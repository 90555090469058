import React, { useEffect, useState, useContext } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import CartTable from "../../components/cart/CartTable";
import { CartsContext } from "../../context/CartsContext";
import moment from "moment";

const AdminCart = () => {
  const [query, setQuery] = useState("");
  const [fechaInicio, setFechaInicio] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [fechaFin, setFechaFin] = useState(
    moment().startOf("month").add(1, "month").format("YYYY-MM-DD")
  );

  const { getCarts, cart_items } = useContext(CartsContext);

  useEffect(() => {
    getCarts({ start_date: fechaInicio, end_date: fechaFin, query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio, fechaFin, query]);

  const handleChange = (start_date, end_date) => {
    setFechaInicio(start_date);
    setFechaFin(end_date);
  };

  return (
    <div className="pe-3">
      <PanelTitleDate title="Carritos" callback={handleChange} />
      <div className="card no-scale shadow">
        <input
          type="text"
          value={query}
          placeholder="Buscar..."
          className="form-control mb-3"
          onChange={(e) => setQuery(e.target.value)}
        />
        <CartTable cart_items={cart_items} />
      </div>
    </div>
  );
};

export default AdminCart;
