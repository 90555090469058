import { useEffect, useContext } from "react";
import DynamicForm from "../global/DynamicForm";
import { MilestonesContext } from "../../context/MilestonesContext";

const MilestoneForm = ({ milestone_id, handleCancel }) => {
  const {
    milestone,
    getMilestones,
    saveMilestone,
    createMilestone,
    getSingleMilestone,
    setPropertyMilestone,
  } = useContext(MilestonesContext);

  useEffect(() => {
    if (isNaN(parseInt(milestone_id))) {
      createMilestone();
    } else {
      getSingleMilestone(milestone_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DynamicForm
      object={milestone}
      objectType="milestone"
      exclude={[
        "createdAt",
        "updatedAt",
        "deletedAt",
        "min_videos",
        "reward_classes",
        "expiration_days",
        "min_reservations",
        "min_purchase_amount",
      ]}
      saveAction={saveMilestone}
      handleCancel={handleCancel}
      handleCallback={getMilestones}
      modifier={setPropertyMilestone}
    />
  );
};

export default MilestoneForm;
