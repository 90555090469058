import React, { useContext, useEffect } from "react";
import Switch from "react-switch";
import { getValue } from "../../utils";
import PaqueteTypeForm from "./PaqueteTypeForm";
import { PackagesContext } from "../../context/PackageContext";

const SingleClassOptions = ({ class_package, class_types, modifier }) => {
  const {
    addClassPackageType,
    removeClassPackageType,
    setPropertyClassPackageType,
  } = useContext(PackagesContext);

  useEffect(() => {
    if (class_package && class_package !== null) {
      if (class_package.is_special_event) {
        if (class_package.class_amount !== null) {
          modifier("class_amount", null);
        }
        if (class_package.is_subscription) {
          modifier("is_subscription", false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_package]);

  const renderClassPackageTypes = () => {
    if (Array.isArray(class_package.class_package_types)) {
      const defaultForm = (
        <PaqueteTypeForm
          key="default"
          isDefault
          modifier={(key, value) => modifier(key, value)}
          class_types={class_types}
          class_package_type={class_package}
        />
      );
      return [
        defaultForm,
        ...class_package.class_package_types.map((current_type, index) => (
          <PaqueteTypeForm
            key={current_type.class_package_type_id}
            modifier={(key, value) => {
              setPropertyClassPackageType(current_type.object_key, key, value);
            }}
            class_types={class_types}
            class_package_type={current_type}
            handleDelete={removeClassPackageType}
          />
        )),
      ];
    }
  };

  const renderClassPackageTypesButton = () => {
    if (!class_package.is_special_event) {
      return (
        <button
          type="button"
          onClick={addClassPackageType}
          className="btn btn-sm btn-outline-dark mb-3 d-block"
        >
          <i className="fa fa-plus me-2"></i>Agregar Clases
        </button>
      );
    }
  };

  return (
    <div>
      <h4 className="border-bottom pb-2">Clases Presenciales</h4>
      <div className="row mb-3">
        <div className="col-6">
          <label>Evento Especial</label>
        </div>
        <div className="col-6">
          <Switch
            checked={getValue(class_package, "is_special_event", "boolean")}
            onChange={(checked) => modifier("is_special_event", checked)}
          />
        </div>
      </div>

      {/* <div className="row mb-3">
        <div className="col-6">
          <label>Permitir Invitado</label>
        </div>
        <div className="col-6">
          <Switch
            checked={getValue(class_package, "allow_guest", "boolean")}
            onChange={(checked) => modifier("allow_guest", checked)}
          />
        </div>
      </div> */}

      {renderClassPackageTypes()}
      {renderClassPackageTypesButton()}
    </div>
  );
};

export default SingleClassOptions;
