import React, { useContext, useState, useEffect } from "react";
import { PostsContext } from "../../context/PostsContext";
import OffCanvas from "../../components/global/OffCanvas";
import ProfileInfo from "../../components/profile/ProfileInfo";
import { ThreadsContext } from "../../context/ThreadsContext";
import Posts from "../../components/posts/Posts";
import VideoTeaser from "../../components/videos/VideoTeaser";
import { VideosContext } from "../../context/VideosContext";
import RatingComments from "../../components/ratings/RatingComments";

const AdminComunidad = () => {
  const [videoId, setVideoId] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const { max, posts, setPost, getPosts } = useContext(PostsContext);
  const { video, setVideo, getSingleVideo } = useContext(VideosContext);
  const { thread, setThread, getSingleThread } = useContext(ThreadsContext);

  useEffect(() => {
    getPosts();
    return clearOffcanvas;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videoId && videoId !== null) {
      getSingleVideo(videoId);
      setShowOffCanvas(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  useEffect(() => {
    if (customer !== null && !showOffCanvas) {
      setShowOffCanvas(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (!showOffCanvas) {
      clearOffcanvas();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOffCanvas]);

  useEffect(() => {
    if (threadId !== null) {
      getSingleThread(threadId);
      setShowOffCanvas(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId]);

  const clearOffcanvas = () => {
    setCustomer(null);
    setThreadId(null);
    setVideoId(null);
    setThread(null);
    setVideo(null);
    setPost(null);
  };

  const renderOffCanvasContent = () => {
    if (video !== null) {
      return (
        <div>
          <VideoTeaser hideInfo video={video} />
          <RatingComments video_id={video.video_id} />
        </div>
      );
    }
    if (thread !== null) {
      return (
        <Posts
          max={max}
          hideReplies
          title="Hilo"
          posts={thread.posts}
          handleThread={setThreadId}
        />
      );
    }
    if (customer !== null) {
      return <ProfileInfo customer={customer} />;
    }
  };

  return (
    <div id="posts" className="container-fluid px-4 px-md-5 bg-white">
      <Posts
        max={max}
        fullHeight
        posts={posts}
        title="Comunidad"
        setCustomer={setCustomer}
        handleVideo={setVideoId}
        handleThread={setThreadId}
        handleCallback={getPosts}
      />
      <OffCanvas
        showOffCanvas={showOffCanvas}
        setShowOffCanvas={setShowOffCanvas}
      >
        {renderOffCanvasContent()}
      </OffCanvas>
    </div>
  );
};

export default AdminComunidad;
