const elements = {
  Attention: {
    highlight: "text",
    content: "text",
  },
  Banner: {
    file: "file",
    src: "text",
  },
  Bonus: {
    number: "number",
    title: "text",
    name: "text",
    content_1: "text",
    content_2: "text",
    content_3: "text",
    tagline: "text",
    color: "text",
    image_source: "text",
    value: "number",
  },
  BulletList: {
    items: "array",
    item_name: "text",
  },
  CallToAction: {
    title: "text",
    tagline: "text",
    color: "text",
    text_color: "text",
    href: "text",
  },
  Checkout: {
    title: "text",
    product: "text",
    page_title: "text",
    product_title: "text",
    product_description: "text",
    product_price: "number",
    product_image: "text",
    product_color: "text",
    requires_shipping: "boolean",
    contact_title: "text",
    address_title: "text",
    redirect: "text",
    is_free: "boolean",
    destination: "email",
  },
  CustomContent: {
    tag: "text",
    content: "text",
    className: "text",
  },
  DoubleButton: {
    elementId: "text",
    leftButtonLink: "text",
    leftButtonTitle: "text",
    rightButtonLink: "text",
    rightButtonTitle: "text",
  },
  ImageText: {
    file: "file",
    src: "text",
    title: "text",
    content: "custom",
    imageSide: ["left", "right"],
    buttonText: "",
    buttonLink: "",
  },
  VideoText: {
    video_source: "text",
    title: "text",
    content: "custom",
    videoSide: ["left", "right"],
    buttonText: "",
    buttonLink: "",
  },
  MainPitch: {
    attention: "text",
    highlight: "text",
    title: "text",
    tagline: "text",
  },
  NumberList: {
    items: "array",
  },
  Pitch: {
    main_pitch: "text",
    tagline: "text",
    description: "text",
    color: "text",
  },
  PictureTestimonial: {
    name: "text",
    content: "text",
    image_source: "text",
  },
  Product: {
    title: "text",
    description: "text",
    image_source: "text",
    price: "number",
    color: "text",
    call_to_action: "boolean",
  },
  SectionTitle: {
    number: "number",
    title: "text",
  },
  Secure: {
    name: "text",
  },
  Stacking: {
    title: "text",
    items: "array",
    value: "number",
    tagline: "text",
    color: "text",
    text_color: "text",
    cta_title: "text",
    cta_tagline: "text",
    cta_href: "text",
  },
  ThankYou: {
    product_title: "text",
  },
  VideoTestimonial: {
    content: "text",
    name: "text",
    video_source: "text",
  },
};

export default elements;