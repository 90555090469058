import React, { useEffect, useState } from "react";
import Select from "react-select";

const SearchSelect = ({
  items,
  itemText,
  itemValue,
  modifier,
  error,
  value,
  placeholderText
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const selectOptions = items?.map((item) => ({
    value: item[itemValue],
    label: item[itemText],
  }));

  const baseStyles = {
    control: (provided) => ({
      ...provided,
      border: "0.5px solid rgb(33, 37, 41)",
      backgroundColor: "#fff",
      borderRadius: "0.25rem",
      paddingLeft: "16px",
      color: "black"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
      backgroundColor: "#f7f7f7",
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      backgroundColor: "#f7f7f7",
      color: "black",
    }),
    options: (provided) => ({
      ...provided,
      backgroundColor: "#f7f7f7",
      color: "black",
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: "#f7f7f7",
    }),
  };

  const customStyles = {
    ...baseStyles,
    control: (provided) => ({
      ...baseStyles.control(provided),
      boxShadow: error ? "0 0 0 1.5px #dc3545" : "none",
    }),
  };

  useEffect(() => {
    if (value && selectOptions && selectedItem === null) {
      const initialOption = selectOptions.find(
        (option) => String(option.value) === String(value)
      );
      if (initialOption) setSelectedItem(initialOption);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectOptions]);

  const handleChange = (selectedOption) => {
    setSelectedItem(selectedOption);
    if (modifier) {
      modifier(selectedOption);
    }
  };

  return (
    <Select
      options={selectOptions}
      value={selectedItem}
      onChange={handleChange}
      isSearchable
      placeholder={placeholderText}
      styles={customStyles}
    />
  );
};

export default SearchSelect;
