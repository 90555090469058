import React from "react";
import { formatMonto } from "../../utils";

const Product = ({
  title,
  description,
  image_source,
  price,
  color,
  call_to_action,
}) => {
  return (
    <div className="container-fluid my-2 p-3" data-aos="fade-up">
      <div className={`card border-${color} border-2 shadow p-4`}>
        <div className="row align-items-center">
          <div className="col-12 col-md-4 p-4">
            <img src={image_source} alt={title} className="mw-50 w-100 d-block m-auto" />
          </div>
          <div className="col-12 col-md-8 p-4">
            <h3 className="bold">{title}</h3>
            <p>{description}</p>
            <p className={`h4 text-${color} bold`}>
              {price > 0 ? `$${formatMonto(price)} MXN` : "GRATIS"}
            </p>
            {call_to_action && (
              <button
                className={`btn btn-${color} btn-cta bold`}
                onClick={call_to_action}
              >
                {price > 0 ? "Comprar" : "Descargar"} Ahora
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
