import React, { useState, useEffect } from "react";
import { S3_ENDPOINT } from "../../utils";

const FileInput = ({ label, value, type, valueKey, modifier }) => {
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    if (value && value !== null && type === "image") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPicture(e.target.result);
      };
      if (typeof value === "object") {
        if (value.size) {
          reader.readAsDataURL(value);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFiles = (e) => {
    const currentFile = e.target.files[0];
    if (typeof modifier === "function") {
      modifier(valueKey, currentFile);
    }
  };

  const renderImage = () => {
    if (typeof value === "object" && value !== null && picture === null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${value.name}.${value.type}`}
          className="mw-100 w-100 m-auto d-block"
          alt={label}
        />
      );
    }
    if (picture !== null) {
      return <img src={picture} alt={label} className="d-block m-auto mw-100 w-100" />;
    }
    return (
      <div className="border text-center">
        <i className="fas fa-image fa-3x"></i>
      </div>
    );
  };

  return (
    <div className="row align-items-center my-3">
      <div className="col-4 col-md-4">{renderImage()}</div>
      <div className="col-8 col-md-8">
        <label>{label}</label>
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      </div>
    </div>
  );
};

export default FileInput;
