import React from "react";
import { getValue } from "../../utils";

const PaqueteTypeForm = ({
  modifier,
  isDefault,
  class_types,
  handleDelete,
  class_package_type,
}) => {
  const renderClassTypes = () => {
    if (Array.isArray(class_types) && !isDefault) {
      return [
        <option value="" key="empty">
          Elige un tipo...
        </option>,
        ...class_types
          .filter((current) => getValue(current, "restricted", "boolean"))
          .map((current) => (
            <option key={current.class_type_id} value={current.class_type_id}>
              {current.name}
            </option>
          )),
      ];
    }
  };
  return (
    <div className="row">
      <div className="col">
        <label>Tipo</label>
        <select
          className="form-control"
          value={getValue(class_package_type, "class_type_id")}
          onChange={(e) => modifier("class_type_id", e.target.value)}
        >
          {isDefault && <option value="">Clases No Restringidas</option>}
          {renderClassTypes()}
        </select>
      </div>
      <div className="col">
        <label>Clases</label>
        <input
          type="number"
          className="form-control"
          value={getValue(class_package_type, "class_amount")}
          onChange={(e) => modifier("class_amount", e.target.value)}
        />
      </div>
      <div className="col">
        <label>Vigencia</label>
        <div className="row align-items-center mb-3">
          <div className="col">
            <input
              type="number"
              className="form-control"
              value={getValue(class_package_type, "expiration_days")}
              onChange={(e) => modifier("expiration_days", e.target.value)}
            />
          </div>
          <div className="col">días</div>
        </div>
      </div>

      <div className="col">
        <label>Horarios</label>
        <input
          type="text"
          className="form-control"
          value={getValue(class_package_type, "slots")}
          onChange={(e) => modifier("slots", e.target.value)}
        />
      </div>

      <div className="col">
        <label style={{ visibility: "hidden" }}>Acciones</label>
        {!isDefault && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(class_package_type.object_key);
            }}
            type="button"
            className="btn d-block btn-sm btn-outline-danger"
          >
            <i className="fa fa-trash"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default PaqueteTypeForm;
