import ReactSwitch from "react-switch";

const Input = ({
  id,
  args,
  type,
  label,
  value,
  options,
  modifier,
  required,
  className,
}) => {
  const renderOptions = (options) => {
    return options.map(({ value, label }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ));
  };
  const renderInput = () => {
    if (type === "select") {
      return (
        <select
          value={value}
          className="form-select bg-light"
          onChange={(e) => modifier(id, e.target.value)}
        >
          {renderOptions(options)}
        </select>
      );
    }
    if (type === "boolean") {
      return (
        <div className="row">
          <div className="col-8">
            <label className="text-capitalize">{label}</label>
          </div>
          <div className="col-4">
            <ReactSwitch
              className="me-2"
              checked={value === true}
              onChange={(checked) => modifier(id, checked)}
            />
          </div>
        </div>
      );
    }
    if (type === "textarea") {
      return (
        <textarea
          rows="4"
          id={id}
          name={id}
          required={required}
          value={value}
          className="form-control bg-light mb-3"
          onChange={(event) => modifier(id, event.target.value)}
        />
      );
    }
    return (
      <input
        id={id}
        name={id}
        type={type}
        value={value}
        required={required}
        className="form-control bg-light"
        onChange={(event) => {
          if(type === "file") return modifier(id, event.target.files[0]);
          modifier(id, event.target.value)
        }}
      />
    );
  };

  return (
    <div className={`${className ? className : "col-12"} mb-2`}>
      {type !== "boolean" && (
        <label htmlFor={id} className="form-label text-capitalize mb-0">
          {label}
        </label>
      )}
      {renderInput()}
    </div>
  );
};

export default Input;
